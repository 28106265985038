<template>
    <div :class="['orders-group-material-modal-material__container', { '--even': isEven }]">
        <div class="orders-group-material-modal-material__content-left">
            <div
                class="orders-group-material-modal-material__close-button-container"
                @click="$emit('removeOperation', operation.id)"
            >
                <CloseIcon />
            </div>

            <span class="orders-group-material-modal-material__order-name">
                {{ operation.name }}
            </span>
        </div>

        <div class="orders-group-material-modal-material__content-right">
            <QuantityConsumedInput
                :value="operation.quantityConsumed"
                :formatter="handleFormatterQuantityConsumed"
                :state="operation.valid"
                @increment="handleIncrementAndDescrement('increment')"
                @decrement="handleIncrementAndDescrement('decrement')"
                @input="updateConsumedQuantity"
                @blur="validateQuantityConsumed"
            />
        </div>
    </div>
</template>

<script>
    import { BSidebar, VBToggle } from "bootstrap-vue";
    import CloseIcon from "@core/assets/formatted-icons/circle-wrong.svg";
    import ArrowIcon from "@core/assets/icons/arrow-icon.svg";
    import SysButton from "@/@core/components/sys-button/index.vue";
    import QuantityConsumedInput from "./QuantityConsumedInput.vue";
    import { mapGetters, mapMutations } from "vuex";
    import { handleFormatQuantityConsumedToNumber } from "@/views/pages/operator/utils";

    export default {
        components: {
            BSidebar,
            CloseIcon,
            ArrowIcon,
            QuantityConsumedInput,
            SysButton
        },
        directives: {
            "b-toggle": VBToggle
        },
        props: {
            isEven: {
                type: Boolean,
                required: true,
                default: false
            },
            operation: {
                type: Object,
                required: true,
                default: () => ({
                    id: -1,
                    name: "A202",
                    quantityRequired: 0,
                    required: false,
                    quantityConsumed: null
                })
            }
        },
        computed: {
            ...mapGetters("production_progress", ["ordersGroupMaterialModal"])
        },
        methods: {
            ...mapMutations("production_progress", [
                "UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL",
                "UPDATE_ORDERS_GROUP_MATERIAL_MODAL_OPERATIONS_MATERIAL",
                "UPDATE_ORDERS_GROUP_MATERIAL_MODAL_HAS_CHANGES"
            ]),
            formatCurrencyWithoutSymbol(value) {
                const numberValue = this.handleFormatQuantityConsumedToNumber(value);

                let formattedValue = new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numberValue);

                return formattedValue;
            },
            handleFormatterQuantityConsumed(value) {
                const hasOperation = this.ordersGroupMaterialModal.material.operations.some(
                    (operation) => operation.id === this.operation.id
                );

                if (!hasOperation || value === "0,0" || !value) return "";

                return this.formatCurrencyWithoutSymbol(value);
            },
            validateQuantityConsumed() {
                const operationsLocal = [...this.ordersGroupMaterialModal.material.operations];

                const operationIndex = operationsLocal.findIndex(
                    (operation) => operation.id === this.operation.id
                );

                if (operationIndex === -1) {
                    return;
                }

                if (
                    (this.operation.required && !this.operation.quantityConsumed) ||
                    !handleFormatQuantityConsumedToNumber(this.operation.quantityConsumed)
                ) {
                    operationsLocal[operationIndex].valid = false;
                } else {
                    operationsLocal[operationIndex].valid = true;
                }

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_OPERATIONS_MATERIAL(operationsLocal);
            },
            updateConsumedQuantity(value) {
                const materialLocal = { ...this.ordersGroupMaterialModal.material };
                const operationIndex = materialLocal.operations.findIndex(
                    (operation) => operation.id === this.operation.id
                );

                if (operationIndex === -1) {
                    return;
                }

                materialLocal.operations[operationIndex].quantityConsumed = value;

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });

                this.handleCalcTotalConsumed(materialLocal);

                if (!this.ordersGroupMaterialModal.hasChanges) {
                    this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_HAS_CHANGES(true);
                }
            },
            handleFormatQuantityConsumedToNumber(value) {
                const replacedValue = value.replace(/[^\d]/g, "");

                const onlyDigits = replacedValue
                    .split("")
                    .filter((s) => /\d/.test(s))
                    .join("")
                    .padStart(3, "0");

                if (isNaN(onlyDigits)) {
                    return;
                }

                const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);

                return Number(digitsFloat);
            },
            handleIncrementAndDescrement(operation = "increment") {
                const materialLocal = { ...this.ordersGroupMaterialModal.material };

                const opereationIndex = this.ordersGroupMaterialModal.material.operations.findIndex(
                    (operation) => operation.id === this.operation.id
                );

                if (opereationIndex === -1) {
                    return;
                }

                const operationLocal = {
                    ...this.ordersGroupMaterialModal.material.operations[opereationIndex]
                };

                const quantityConsumed = this.handleFormatQuantityConsumedToNumber(
                    operationLocal.quantityConsumed
                );

                let newQuantityConsumed =
                    operation === "increment" ? quantityConsumed + 1 : quantityConsumed - 1;

                if (newQuantityConsumed < 0) {
                    newQuantityConsumed = 0;
                }

                const formattedNewQuantityConsumed = new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(newQuantityConsumed);

                materialLocal.operations[opereationIndex].quantityConsumed =
                    formattedNewQuantityConsumed;

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });

                if (!this.ordersGroupMaterialModal.hasChanges) {
                    this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_HAS_CHANGES(true);
                }

                this.handleCalcTotalConsumed(materialLocal);
                this.validateQuantityConsumed();
            },
            handleCalcTotalConsumed(material) {
                const materialLocal = { ...material };

                const totalConsumed = materialLocal.operations.reduce((acc, operation) => {
                    const quantityConsumed = this.handleFormatQuantityConsumedToNumber(
                        operation.quantityConsumed
                    );

                    return acc + quantityConsumed;
                }, 0);

                const formattedTotalConsumed = new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(totalConsumed);

                materialLocal.totalConsumed = formattedTotalConsumed;

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });
            }
        }
    };
</script>

<style lang="scss">
    #orders-group-material-modal {
        .b-sidebar-header {
            padding: 0 !important;
        }
    }
</style>

<style lang="scss" scoped>
    #orders-group-material-modal {
        .orders-group-material-modal-material__container {
            display: flex;
            gap: 16px;
            align-items: center;
            padding: 24px 16px;
            background: #fff;

            &.--even {
                background: #fafafa !important;
            }

            .orders-group-material-modal-material__content-left {
                display: flex;
                align-items: center;
                gap: 16px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;

                .orders-group-material-modal-material__close-button-container {
                    padding: 12px;
                    height: fit-content;
                    cursor: pointer;
                    display: flex;

                    svg {
                        min-height: 14px;
                        max-height: 14px;
                        min-width: 14px;
                        max-width: 14px;
                        fill: #d32f2f;
                    }
                }

                .orders-group-material-modal-material__order-name {
                    color: #4c4541;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                }
            }

            .orders-group-material-modal-material__content-right {
                min-width: 242px;
                max-width: 242px;
                display: flex;
                gap: 8px;
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #orders-group-material-modal {
            .orders-group-material-modal-material__container {
                padding: 24px 29px !important;
                .orders-group-material-modal-material__content-left {
                    .orders-group-material-modal-material__order-name {
                        font-size: 22px !important;
                        line-height: 30px !important;
                    }

                    .orders-group-material-modal-material__close-button-container {
                        padding: 14px !important;

                        svg {
                            min-height: 18px !important;
                            max-height: 18px !important;
                            min-width: 18px !important;
                            max-width: 18px !important;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 570px) {
        #orders-group-material-modal {
            .orders-group-material-modal-material__container {
                padding: 24px 16px !important;
                flex-direction: column !important;
                gap: 16px !important;
                .orders-group-material-modal-material__content-left {
                    flex-direction: row-reverse !important;
                    gap: 16px;
                    .orders-group-material-modal-material__order-name {
                        font-size: 14px !important;
                        line-height: 20px !important;
                    }
                }

                .orders-group-material-modal-material__content-right {
                    width: 100%;
                    min-width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
</style>
