export function handleFormatQuantityConsumedToNumber(value) {
    if (!value) return 0;

    const replacedValue = value.replace(/[^\d]/g, "");

    const onlyDigits = replacedValue
        .split("")
        .filter((s) => /\d/.test(s))
        .join("")
        .padStart(3, "0");

    if (isNaN(onlyDigits)) {
        return;
    }

    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);

    return Number(digitsFloat);
}

export function formatCurrencyWithoutSymbol(value) {
    if (value === null || value === undefined) return "";

    const numberValue =
        typeof value === "string" ? handleFormatQuantityConsumedToNumber(value) : value;

    let formattedValue = new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(numberValue);

    return formattedValue;
}

export function handleConsumedRequired(quantityRequired, quantityConsumed, required) {
    if (quantityRequired !== null && !quantityConsumed && !required) {
        return quantityRequired;
    }

    return quantityConsumed;
}
