<template>
    <div class="record-materials-modal-materials__container">
        <div class="record-materials-modal-materials__filter-input-container">
            <SearchIcon class="record-materials-modal-materials__search-icon" />
            <b-form-tags
                ref="formTagMaterials"
                :value="recordMaterialsModal.filterInput"
                class="record-materials-modal-materials__filter-input"
                duplicate-tag-text=""
                :placeholder="
                    recordMaterialsModal.filterInput.length === 0
                        ? $t('ProductionProgress.SearchForMaterials')
                        : ''
                "
                remove-on-delete
                @input="handleFilterMaterials"
            />
        </div>

        <div class="record-materials-modal-materials__content">
            <div
                class="record-materials-modal-materials__list-container"
                v-if="!flags.isMultipleOrders"
            >
                <Material
                    v-for="(material, index) in recordMaterialsModal.filteredMaterialsList"
                    :key="`material-${index}`"
                    :material="material"
                />
            </div>

            <div
                class="record-materials-modal-materials__list-container"
                v-if="flags.isMultipleOrders"
            >
                <OrderGroupMaterial
                    v-for="(material, index) in recordMaterialsModal.filteredMaterialsList"
                    :key="`material-${index}`"
                    :material="material"
                />
            </div>

            <div
                class="record-materials-modal-materials__not-found-block-container"
                v-if="recordMaterialsModal.filteredMaterialsList.length == 0"
            >
                <div class="not-found-block">
                    <NoResultIcon />
                    <h3>{{ $t("NoResultsFound") }}</h3>
                </div>
            </div>
        </div>

        <div class="record-materials-modal-materials__footer-container">
            <sys-button
                scheme="square"
                variant="outline"
                :size="handleFooterButtonSize()"
                :full="windowWidth <= 768"
                @click="handleOpenAddMaterialsModal"
            >
                {{ $t("ProductionProgress.AddMaterials") }}
            </sys-button>

            <sys-button
                scheme="square"
                variant="contained"
                :size="handleFooterButtonSize()"
                :full="windowWidth <= 768"
                @click="saveChanges"
                :disabled="!recordMaterialsModal.hasChanges"
                v-if="!flags.isMultipleOrders"
            >
                {{ $t("ProductionProgress.SaveChanges") }}
            </sys-button>
        </div>
    </div>
</template>

<script>
    import { BFormTags } from "bootstrap-vue";
    import Material from "./Material.vue";
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import NoResultIcon from "@core/assets/formatted-icons/no-result-icon.svg";
    import SearchIcon from "@core/assets/formatted-icons/search-icon.svg";
    import SysButton from "@/@core/components/sys-button/index.vue";
    import OrderGroupMaterial from "./OrderGroupMaterial.vue";
    import { filterMaterials } from "@/views/pages/operator/components/RecordMaterialsModal/utils";
    import { handleFormatQuantityConsumedToNumber } from "@/views/pages/operator/utils";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

    export default {
        components: {
            BFormTags,
            Material,
            NoResultIcon,
            SearchIcon,
            SysButton,
            OrderGroupMaterial,
            ToastificationContent
        },
        data: () => ({}),
        computed: {
            ...mapGetters("app", ["windowWidth"]),
            ...mapGetters("production_progress", ["recordMaterialsModal", "flags"])
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("production_progress", [
                "UPDATE_RECORD_MATERIALS_MODAL_MATERIALS",
                "UPDATE_RECORD_MATERIALS_MODAL_FILTER_INPUT",
                "UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST",
                "UPDATE_ADD_MATERIAL_MODAL_MATERIALS",
                "UPDATE_ADD_MATERIAL_MODAL_FILTERED_MATERIALS_LIST"
            ]),
            ...mapActions("production_progress", [
                "openAddMaterialsModal",
                "closeRecordMaterialsModal"
            ]),
            async handleOpenAddMaterialsModal() {
                const { order_id, multiple } = this.$router.currentRoute.params;
                const GET_AVAILABLE_MATERIALS_URL =
                    multiple === "true"
                        ? `api/mysfc/operation/multiple/available-materials`
                        : `api/mysfc/operation/${order_id}/available-materials`;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_AVAILABLE_MATERIALS_URL);

                    const materials = response?.data?.data || [];

                    this.UPDATE_ADD_MATERIAL_MODAL_FILTERED_MATERIALS_LIST([...materials]);
                    this.UPDATE_ADD_MATERIAL_MODAL_MATERIALS([...materials]);
                    this.openAddMaterialsModal();
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            normalizeString(str) {
                return str
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase();
            },
            handleFilterMaterials(filters) {
                this.UPDATE_RECORD_MATERIALS_MODAL_FILTER_INPUT([...filters]);
                filterMaterials(
                    filters,
                    this.recordMaterialsModal.materials,
                    this.UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST
                );
            },
            handleFooterButtonSize() {
                if (this.windowWidth > 768) {
                    return "md";
                }

                if (this.windowWidth > 570 && this.windowWidth <= 768) {
                    return "xl";
                }

                if (this.windowWidth <= 570) {
                    return "xs";
                }
            },
            validateMaterials() {
                const materialsLocal = [...this.recordMaterialsModal.materials];

                let isValid = true;
                materialsLocal.forEach((material) => {
                    if (
                        (material.required && !material.quantityConsumed) ||
                        !handleFormatQuantityConsumedToNumber(material.quantityConsumed)
                    ) {
                        material.valid = false;
                        isValid = false;
                    } else {
                        material.valid = true;
                    }
                });

                return isValid;
            },
            validateFields() {
                const materialsValidation = this.validateMaterials();

                return materialsValidation;
            },
            async saveChanges() {
                const validationResult = this.validateFields();

                if (!validationResult) {
                    return;
                }

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await this.saveConsumedMaterials();
                    this.closeRecordMaterialsModal();
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            handleCreatePayloadToSaveConsumedMaterials() {
                const materialsListLocal = [...this.recordMaterialsModal.materials];

                const formattedMaterialsList = materialsListLocal.map((material) => ({
                    id: material.id,
                    quantityConsumed: material.quantityConsumed
                        ? handleFormatQuantityConsumedToNumber(material.quantityConsumed)
                        : null
                }));

                return formattedMaterialsList;
            },
            async saveConsumedMaterials() {
                const { order_id } = this.$router.currentRoute.params;
                const PUT_CONSUMED_MATERIALS_URL = `api/mysfc/operation/${order_id}/consumed-materials`;
                const payload = this.handleCreatePayloadToSaveConsumedMaterials();

                try {
                    const response = await this.$http2.put(PUT_CONSUMED_MATERIALS_URL, payload);
                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        this.$t("ProductionProgress.MaterialsUpdated"),
                        "success"
                    );

                    return Promise.resolve(response);
                } catch (error) {
                    console.error(error);

                    return Promise.reject(error);
                }
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        variant
                    }
                });
            }
        },

        mounted() {
            const vm = this;
            $(() => {
                document
                    .getElementsByClassName("record-materials-modal-materials__filter-input")[0]
                    .setAttribute("tabIndex", "-1");

                const slider = document.querySelector(".b-form-tags-list");
                let mouseDown = false;
                let startX;
                let scrollLeft;

                const startDragging = function (e) {
                    mouseDown = true;
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                };
                const stopDragging = function (event) {
                    mouseDown = false;
                };

                slider.addEventListener("mousemove", (e) => {
                    e.preventDefault();
                    if (!mouseDown) {
                        return;
                    }
                    const x = e.pageX - slider.offsetLeft;
                    const scroll = x - startX;
                    slider.scrollLeft = scrollLeft - scroll;
                });

                slider.addEventListener("mousedown", startDragging, false);
                slider.addEventListener("mouseup", stopDragging, false);
                slider.addEventListener("mouseleave", stopDragging, false);

                $(".b-form-tags-input").blur((event) => {
                    const input = vm.$refs.formTagMaterials;
                    input.addTag(event.target.value);
                });
            });
        }
    };
</script>

<style lang="scss">
    #record-materials-modal {
        .record-materials-modal-materials__container {
            .record-materials-modal-materials__filter-input-container {
                display: flex;

                .record-materials-modal-materials__filter-input {
                    min-height: 51px;
                    height: 100%;
                    padding: 0;
                    border-radius: 0;
                    margin: 0;
                    border: 0;

                    ul {
                        width: 100%;
                        cursor: text !important;

                        .b-form-tag {
                            margin-top: 0 !important;
                        }
                    }

                    &.focus {
                        border-color: #cfc4be !important;
                        box-shadow: none;
                    }

                    &.b-form-tags {
                        padding: 0 !important;
                        display: flex;
                        align-items: center !important;

                        .b-form-tags-list {
                            width: 100% !important;
                            margin-top: 0;
                            height: 100% !important;
                            flex-wrap: nowrap !important;
                            display: flex !important;
                            align-items: center !important;
                            overflow: scroll;
                            overflow-y: hidden;
                            cursor: grabbing;
                            cursor: -webkit-grabbing;

                            &::-webkit-scrollbar {
                                display: none !important;
                            }

                            .b-from-tags-field {
                                margin: 9px 0;
                            }
                        }
                    }

                    .b-form-tags-button {
                        display: none;
                    }

                    .b-form-tag {
                        background-color: #974900;
                        margin-right: 0.6rem;
                        padding: 2px 8px !important;
                        font-size: 13px;
                    }

                    ::placeholder {
                        color: #cfc4be;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) and (min-width: 570px) {
        #record-materials-modal {
            .record-materials-modal-materials__container {
                .record-materials-modal-materials__filter-input-container {
                    .record-materials-modal-materials__filter-input {
                        min-height: 81px;
                    }
                }
            }
        }
    }

    @media (max-width: 570px) {
        #record-materials-modal {
            .record-materials-modal-materials__container {
                .record-materials-modal-materials__filter-input-container {
                    .record-materials-modal-materials__filter-input {
                        min-height: 47px;
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    .record-materials-modal-materials__content {
        flex-grow: 1;
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #cfc4be;
            border-radius: 9px;
        }
    }

    #record-materials-modal {
        .record-materials-modal-materials__container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            .record-materials-modal-materials__filter-input-container {
                width: 100%;
                background: #fff;
                border-bottom: 1px solid #cfc4be;
                display: flex;
                gap: 16px;
                padding: 0 29px;
                align-items: center;
                position: sticky;
                top: 0;

                .record-materials-modal-materials__search-icon {
                    min-height: 16px;
                    min-width: 16px;
                    max-height: 16px;
                    max-width: 16px;
                    fill: #cfc4be;
                }
            }

            .record-materials-modal-materials__list-container {
                display: flex;
                flex-direction: column;
            }

            .record-materials-modal-materials__not-found-block-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .not-found-block {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 24px;
                    width: 100%;
                    flex-direction: column;
                    gap: 24px;

                    h3 {
                        color: #4c4541;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 30px;
                        margin: 0;
                    }

                    svg {
                        height: 80px;
                        width: 80px;
                        fill: #4c4541;
                    }
                }
            }

            .record-materials-modal-materials__footer-container {
                display: flex;
                padding: 15.5px 29px;
                border-top: 1px solid #cfc4be;
                flex-wrap: nowrap;
                justify-content: flex-end;
                width: 100%;
                gap: 16px;
                background: #fff;

                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.175px;
                }

                .add-materials-btn {
                    padding: 8px 20px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border: 1px solid #974900;

                    color: #974900;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.175px;

                    &:hover {
                        background: #ffede2;
                    }

                    &:active {
                        background: #ffdbc4;
                    }
                }

                .save-changes-btn {
                    padding: 9px 21px;
                    background: #974900;
                    color: #fff;

                    &:hover {
                        background-color: #9f5714;
                    }

                    &:active {
                        background-color: #a45f1f;
                    }

                    &:disabled {
                        background: #ece0db !important;
                        color: #7e7570 !important;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) and (min-width: 570px) {
        #record-materials-modal {
            .record-materials-modal-materials__container {
                .record-materials-modal-materials__filter-input-container {
                    .record-materials-modal-materials__search-icon {
                        min-height: 22px !important;
                        min-width: 22px !important;
                        max-height: 22px !important;
                        max-width: 22px !important;
                    }
                }
            }

            .record-materials-modal-materials__footer-container {
                width: 100%;
            }
        }
    }

    @media (max-width: 570px) {
        #record-materials-modal {
            .record-materials-modal-materials__container {
                .record-materials-modal-materials__filter-input-container {
                    padding: 0 16px !important;

                    .record-materials-modal-materials__search-icon {
                        min-height: 14px !important;
                        min-width: 14px !important;
                        max-height: 14px !important;
                        max-width: 14px !important;

                        path {
                            fill: #cfc4be;
                        }
                    }
                }

                .record-materials-modal-materials__not-found-block-container {
                    .not-found-block {
                        svg {
                            height: 50px !important;
                            width: 50px !important;
                        }

                        h3 {
                            font-size: 16px !important;
                            line-height: 26px !important;
                        }
                    }
                }

                .record-materials-modal-materials__footer-container {
                    padding: 15.5px 16px;
                    gap: 8px;
                }
            }
        }
    }
</style>
