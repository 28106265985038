<template>
    <div>
        <Loading v-show="operations.length === 0 && isFirstLoading" />

        <NotFound v-if="operations.length === 0 && !isFirstLoading" />

        <div
            v-else
            :class="['order-list']"
        >
            <div
                v-for="(operation, index) in operations"
                :key="`order-card-${index}`"
                @click="pressCard(operation)"
            >
                <OrderCard
                    :operation="operation"
                    :selectable="selectMode"
                />
            </div>

            <Observer @intersect="$emit('loadMoreOperations')" />

            <div
                v-if="selectMode"
                class="float-bar"
            >
                <span
                    v-if="selectedOperations.length === 0"
                    class="filtered-orders"
                >
                    {{ $t("Operator.OrderList.NoOrderSelected") }}
                </span>
                <span
                    v-else-if="selectedOperations.length === 1"
                    class="filtered-orders"
                >
                    {{ selectedOperations.length }}
                    {{ $t("Operator.OrderList.SelectedOrder") }}
                </span>
                <span
                    v-else-if="selectedOperations.length > 1"
                    class="filtered-orders"
                >
                    {{ selectedOperations.length }}
                    {{ $t("Operator.OrderList.SelectedOrders") }}
                </span>

                <div class="box-btn">
                    <button
                        class="btn-cancel"
                        @click="cancelSelection"
                    >
                        {{ $t("ResourceStatus.Cancel") }}
                    </button>

                    <button
                        class="btn-clean"
                        @click="cleanSelectedOperations"
                    >
                        {{ $t("Operator.Clean") }}
                    </button>

                    <button
                        :disabled="selectedOperations.length === 0"
                        class="btn-point"
                        @click="() => $refs.appointmentTypeModal.open()"
                        v-if="checkSelectedOperations()"
                    >
                        {{ $t("Operator.Point") }}
                        <ArrowDown class="arrow-down-icon" />
                    </button>

                    <button
                        :disabled="selectedOperations.length === 0"
                        class="btn-point"
                        @click="() => redirectOperationByFloatBar(1)"
                        v-else
                    >
                        {{ $t("Operator.FinishedOrdersBar.Rework") }}
                    </button>
                </div>
            </div>
        </div>
        <SelectAppointmentTypeModal
            ref="appointmentTypeModal"
            @select="(selectedId) => redirectOperationByFloatBar(selectedId)"
        />
    </div>
</template>

<script>
    import Loading from "@/@core/components/loading";
    import ArrowDown from "@core/assets/icons/arrow-down.svg";
    import Observer from "../events/Observer.vue";
    import OrderCard from "./OrderCard.vue";
    import NotFound from "./NotFound.vue";
    import SelectAppointmentTypeModal from "./SelectAppointmentTypeModal.vue";

    export default {
        components: {
            OrderCard,
            Observer,
            NotFound,
            Loading,
            ArrowDown,
            SelectAppointmentTypeModal
        },
        props: {
            selectMode: {
                type: Boolean,
                default: false
            },
            operations: {
                type: Array
            },
            selectedOperations: {
                type: Array
            },
            isFirstLoading: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                prefix: localStorage.getItem("prefix") || this.$router.currentRoute.params.prefix
            };
        },
        methods: {
            redirectOperationByFloatBar(appointment_type) {
                const isMultipleOperations = this.selectedOperations.length > 1;

                if (!this.selectedOperations[0]?.resource?.length) {
                    return;
                }

                const routerParams = {
                    name: isMultipleOperations
                        ? "operator-production-progress-multiple"
                        : "operator-production-progress",
                    params: {
                        prefix: this.prefix,
                        resource_id: this.selectedOperations[0]?.resource?.[0]?.id ?? null,
                        multiple: isMultipleOperations.toString(),
                        orders: isMultipleOperations ? this.selectedOperations : [],
                        appointment_type
                    }
                };

                if (isMultipleOperations) {
                    routerParams.params.preactor_orders = this.selectedOperations
                        .map((item) => item.operation.id)
                        .join(",");
                }

                if (!isMultipleOperations) {
                    routerParams.params.return_orders = "true";
                    routerParams.params.order_id = this.selectedOperations[0].operation.id;
                }

                this.$router.push(routerParams);
            },
            selectOperation(id) {
                this.$emit("selectOperation", id);
            },
            cleanSelectedOperations() {
                this.$emit("cleanSelectedOperations");
            },
            cancelSelection() {
                this.$emit("update:selectMode", false);
                this.cleanSelectedOperations();
            },
            redirectToOperation(resourceId, operationId, isRework) {
                if (resourceId === null || operationId === null || isRework === null) {
                    return;
                }

                const routerParams = {
                    name: "operator-production-progress",
                    params: {
                        prefix: this.prefix,
                        order_id: operationId,
                        resource_id: resourceId,
                        multiple: "false",
                        return_orders: "true",
                        appointment_type: isRework
                    }
                };

                this.$router.push(routerParams);
            },
            pressCard(operation) {
                if (this.selectMode) {
                    this.selectOperation(operation.operation.id);

                    return;
                }

                this.redirectToOperation(
                    operation?.resource?.[0]?.id ?? null,
                    operation?.operation?.id ?? null,
                    operation?.operation?.isRework ?? null
                );
            },
            checkSelectedOperations() {
                if (this.selectedOperations.length === 0) return true;

                const operation = this.selectedOperations[0];

                return !operation?.operation?.isRework;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .arrow-down-icon {
        width: 16px;
        height: 16px;
    }
    .order-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px 0;
    }

    .float-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 15px;

        background: #ffffff;
        border: 1px solid #cfc4be;
        box-shadow:
            0px 6px 10px rgba(0, 0, 0, 0.14),
            0px 1px 18px rgba(0, 0, 0, 0.12),
            0px 3px 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;

        position: fixed;
        bottom: 71.5px;
        z-index: 10;
        font-size: 12px;
        outline: none;
        right: 28px;
        width: 100%;
        max-width: 662px;

        .filtered-orders {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #4c4541;
            margin-right: auto;
        }
    }

    .box-btn {
        display: flex;
        gap: 15px;
        justify-content: end;

        button {
            font-weight: 600 !important;
            font-size: 14px !important;
            line-height: 20px !important;
            border-radius: 5px;
            padding: 8px 20.25px;
            border: 1px solid transparent;
            letter-spacing: 0.0125em;
        }

        .btn-cancel {
            background: #fff;
            color: #974900;

            &:hover {
                background-color: #ffede2 !important;
            }

            &:active {
                background-color: #ffdbc4 !important;
            }
        }

        .btn-clean {
            color: #974900;
            background-color: #ffffff;
            border-color: #974900;

            &:hover {
                background-color: #ffede2 !important;
            }

            &:active {
                background-color: #ffdbc4 !important;
            }
        }

        .btn-point {
            color: #ffffff !important;
            border-color: #974900 !important;
            background-color: #974900 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            max-width: 119px !important;

            &:disabled {
                background: #ece0db !important;
                color: #7e7570 !important;
                border: transparent !important;

                svg,
                path {
                    fill: #7e7570 !important;
                }

                &:hover,
                &:active {
                    background: #ece0db !important;
                    color: #7e7570 !important;
                }
            }

            &:hover {
                background-color: #9f5714 !important;
            }

            &:active {
                background-color: #a45f1f !important;
            }
        }
    }

    @media (max-width: 768px) and (min-width: 593px) {
        .float-bar {
            right: 0;
            left: 0;
            width: calc(100% - 58px);
            margin: 0 auto;
            gap: 16px !important;
            max-width: unset !important;

            .filtered-orders {
                font-size: 18px;
            }
        }

        .btn-clean {
            height: 46px !important;
        }

        .btn-point {
            height: 46px !important;
            line-height: 33px !important;
            width: 146px !important;
        }
    }

    @media (max-width: 593px) {
        .float-bar {
            right: 0;
            left: 0;
            margin: 0 auto;
            flex-direction: column;
            width: calc(100vw - 56px);
            height: auto;
            gap: 0;

            .filtered-orders {
                margin: 0;
            }

            .box-btn {
                padding: 16px 0 0 0;

                button {
                    padding: 3px 13px !important;
                    font-size: 12px !important;
                    line-height: 20px !important;
                }
            }
        }
    }

    @media (max-width: 575px) {
        .float-bar {
            right: 0;
            left: 0;
            width: calc(100vw - 32px);
        }
    }
</style>
