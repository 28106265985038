<template>
    <div class="add-materials-modal-footer__container">
        <div class="add-materials-modal-footer__content-left">
            <span
                class="add-materials-modal-footer__quantity"
                v-if="addMaterialsModal.selectedMaterialIds.length"
            >
                {{ addMaterialsModal.selectedMaterialIds.length }}
                {{
                    addMaterialsModal.selectedMaterialIds.length > 1
                        ? $t("ProductionProgress.SelectedMaterials")
                        : $t("ProductionProgress.SelectedMaterial")
                }}
            </span>
            <span
                class="add-materials-modal-footer__quantity"
                v-else
            >
                {{ $t("ProductionProgress.NoMaterialsSelected") }}
            </span>
        </div>
        <div class="add-materials-modal-footer__content-right">
            <sys-button
                @click="backToRecordMaterialsModal()"
                scheme="square"
                :size="handleButtonSize()"
                variant="outline"
                :full="windowWidth <= 570"
            >
                {{ $t("ProductionProgress.Cancel") }}
            </sys-button>

            <sys-button
                @click="handleAddMaterials()"
                scheme="square"
                :size="handleButtonSize()"
                variant="contained"
                :full="windowWidth <= 570"
                :disabled="!addMaterialsModal.selectedMaterialIds.length"
            >
                {{ $t("ProductionProgress.Add2") }}
            </sys-button>
        </div>
    </div>
</template>

<script>
    import SysButton from "@/@core/components/sys-button";
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { filterMaterials } from "@/views/pages/operator/components/RecordMaterialsModal/utils";
    import {
        formatCurrencyWithoutSymbol,
        handleConsumedRequired
    } from "@/views/pages/operator/utils";

    export default {
        components: {
            SysButton,
            ToastificationContent
        },
        computed: {
            ...mapGetters("app", ["windowWidth"]),
            ...mapGetters("production_progress", [
                "addMaterialsModal",
                "recordMaterialsModal",
                "flags"
            ])
        },
        methods: {
            ...mapMutations("production_progress", [
                "UPDATE_OPERATIONS_MODAL_SELECTED_OPERATION_IDS",
                "UPDATE_RECORD_MATERIALS_MODAL_MATERIALS",
                "UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST",
                "UPDATE_ADD_MATERIAL_MODAL_SELECTED_MATERIAL_IDS"
            ]),
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapActions("production_progress", ["backToRecordMaterialsModal"]),
            handleButtonSize() {
                if (this.windowWidth >= 768) return "md";
                if (this.windowWidth < 768 && this.windowWidth > 570) return "xl";
                return "xs";
            },
            async getMaterials() {
                const { order_id } = this.$router.currentRoute.params;
                const GET_MATERIALS_URL = `/api/mysfc/operation/${order_id}/materials`;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_MATERIALS_URL);

                    const formattedMaterials =
                        response?.data?.data?.map((material) => ({
                            ...material,
                            valid: null,
                            quantityConsumed: formatCurrencyWithoutSymbol(
                                handleConsumedRequired(
                                    material.quantityRequired,
                                    material.quantityConsumed,
                                    material.required
                                )
                            )
                        })) || [];

                    return Promise.resolve(formattedMaterials);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            async addMaterials() {
                const { order_id } = this.$router.currentRoute.params;
                const POST_ADD_MATERIALS_URL = `api/mysfc/operation/${order_id}/add-materials`;
                const payload = this.addMaterialsModal.selectedMaterialIds;

                try {
                    const response = await this.$http2.post(POST_ADD_MATERIALS_URL, payload);

                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        this.$t("ProductionProgress.MaterialsAdded"),
                        "success"
                    );

                    return Promise.resolve(response);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            handlePersistFilledData(oldList, newList) {
                const formattedNewList =
                    newList?.map((newMaterial) => {
                        const itemInOldList = oldList.find(
                            (oldMaterial) => oldMaterial.id === newMaterial.id
                        );

                        if (itemInOldList) return { ...itemInOldList };

                        return newMaterial;
                    }) || [];

                return formattedNewList;
            },
            async handleAddMaterials() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.addMaterials();
                    this.UPDATE_ADD_MATERIAL_MODAL_SELECTED_MATERIAL_IDS([]);

                    const materialsResponse = await this.getMaterials();

                    const newMatarials = this.flags.isMultipleOrders
                        ? materialsResponse
                        : this.handlePersistFilledData(
                              this.recordMaterialsModal.materials,
                              materialsResponse
                          );
                    this.UPDATE_RECORD_MATERIALS_MODAL_MATERIALS([...newMatarials]);
                    filterMaterials(
                        this.recordMaterialsModal.filterInput,
                        newMatarials,
                        this.UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST
                    );

                    this.backToRecordMaterialsModal();
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        variant
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    #add-materials-modal {
        .add-materials-modal-footer__container {
            display: flex;
            align-items: center;
            padding: 15.5px 29px;
            gap: 16px;
            border-top: 1px solid#CFC4BE;

            .add-materials-modal-footer__content-left {
                display: flex;
                .add-materials-modal-footer__quantity {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }

            .add-materials-modal-footer__content-right {
                margin-left: auto;
                display: flex;
                gap: 16px;
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #add-materials-modal {
            .add-materials-modal-footer__container {
                .add-materials-modal-footer__content-left {
                    .add-materials-modal-footer__quantity {
                        font-size: 22px !important;
                        line-height: 30px !important;
                    }
                }
            }
        }
    }

    @media (max-width: 570px) {
        #add-materials-modal {
            .add-materials-modal-footer__container {
                justify-content: center;
                flex-direction: column !important;
                align-items: center;
                padding: 15.5px 16px;

                .add-materials-modal-footer__content-left {
                    justify-content: center;
                    .add-materials-modal-footer__quantity {
                        font-size: 12px !important;
                        line-height: 16px !important;
                    }
                }

                .add-materials-modal-footer__content-right {
                    gap: 8px !important;
                    margin: 0 !important;
                    width: 100%;
                }
            }
        }
    }
</style>
