<template>
    <div class="input-modal-text-container animation-open-modal-text">
        <ModalHeader :title="modalName" :close="closeModal" />

        <div :class="['input-person-container', inputValue ? '--has-photo' : '']">
            <div class="input-person-wrapper">
                <div v-if="!inputValue" class="center-container">
                    <div class="wrapper-container">
                        <div class="text-input">
                            <div class="text-content">
                                <div class="title">
                                    {{ $t("UploadPhoto") }}
                                </div>
                                <div class="subtitle">
                                    {{ $t("UploadSubtitle") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button ref="fileInput" button-type="save" @click="loadPhoto">
                        <PictureIcon />{{ $t("ChoosePhoto") }}
                    </Button>
                    <div class="or-div">
                        {{ $t("or") }}
                    </div>
                    <Button button-type="side" :disabled="device === 'desktop'" @click="takePhoto">
                        <CameraIcon /> {{ $t("TakePhoto") }}
                    </Button>
                </div>
                <div v-else class="w-100">
                    <div class="h-100">
                        <img :src="inputValue" class="image" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="inputValue" class="footer-modal">
            <div class="file-name">
                {{ fileName }}
            </div>
            <div class="buttons">
                <Button button-type="side" custom-class="footer-button" @click="removePhoto()">
                    {{ $t("RemovePhoto") }}
                </Button>
                <Button button-type="save" custom-class="footer-button" @click="loadPhoto">
                    {{ $t("ChangePhoto") }}
                </Button>
            </div>
        </div>
        <input
            ref="fileInput"
            type="file"
            accept="image/*"
            style="display: none"
            :capture="useCamera ? 'environment' : null"
            @change="handleFileUpload"
        />
    </div>
</template>

<script>
    import Button from "@/views/pages/operator/components/InputPhotoModal/components/Button/Button.vue";
    import { BFormTextarea } from "bootstrap-vue";
    import CameraIcon from "@core/assets/icons/camera-icon.svg";
    import PictureIcon from "@core/assets/icons/picture-icon.svg";
    import ModalHeader from "@/views/pages/operator/components/InputTextModal/components/ModalHeader/ModalHeader.vue";
    import { mapMutations } from "vuex";

    export default {
        components: {
            Button,
            ModalHeader,
            BFormTextarea,
            PictureIcon,
            CameraIcon
        },
        props: {
            inputValue: {
                type: String,
                default: ""
            },
            modalName: {
                type: String,
                default: ""
            },
            fileName: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                closeKeyboard: false,
                useCamera: false,
                currentInputValue: this.inputValue,
                device:
                    window.innerWidth > 768
                        ? "desktop"
                        : window.innerWidth > 425 && window.innerWidth <= 768
                        ? "tablet"
                        : "mobile"
            };
        },
        computed: {},
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        destroyed() {
            window.removeEventListener("resize", this.handleResize);
        },
        mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
            this.addOpenAnimation();
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            handleResize() {
                this.device =
                    window.innerWidth > 768
                        ? "desktop"
                        : window.innerWidth > 425 && window.innerWidth <= 768
                        ? "tablet"
                        : "mobile";
            },
            handleFileUpload(event) {
                this.closeModal();
                this.$emit("handleFileUpload", event);
            },
            addOpenAnimation() {
                const modal = document.querySelector(".input-modal-text-container");
                modal.classList.add("animation-open-modal-text");

                setTimeout(
                    () =>
                        document
                            .querySelector(".input-modal-text-container")
                            .classList.remove("animation-open-modal-text"),
                    1000
                );
            },
            closeModal() {
                const modal = document.querySelector(".input-modal-text-container");
                modal.classList.add("animation-close-modal");
                modal.classList.add("closed-modal");
                this.useCamera = false;
                this.closeKeyboard = true;
                setTimeout(() => {
                    this.$emit("close");
                }, 1000);
            },
            loadPhoto() {
                this.useCamera = false;
                this.$refs.fileInput.click();
            },
            takePhoto() {
                this.useCamera = true;
                this.$refs.fileInput.click();
            },
            removePhoto() {
                this.$emit("removePhoto");
                this.closeModal();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .input-modal-text-container {
        width: 100vw;
        height: 100dvh;
        background: #fff;
        z-index: 99999999;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;

        &.closed-modal {
            top: 100dvh;
        }

        .input-person-container {
            height: calc(100dvh - 62px);
            display: flex;
            align-items: center;
            padding: 40px 45px;
            justify-content: center;

            &.--has-photo {
                padding-bottom: 0;
                height: calc(100dvh - 133px);

                .input-person-wrapper {
                    height: 100%;
                }

                .image {
                    width: -webkit-fill-available;
                    height: -webkit-fill-available;
                }
            }

            .input-person-wrapper {
                display: flex;
                user-select: none;
                width: 100%;
                max-width: 818px;

                .center-container {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    .or-div {
                        padding-block: 16px;
                        color: #4c4541;
                        text-align: center;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    .wrapper-container {
                        padding: 0px 16px 16px;
                        display: flex;
                        flex-direction: column;

                        .text-input {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .text-content {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 8px;
                                padding: 0 16px 24px;

                                .title {
                                    color: #4c4541;
                                    text-align: center;
                                    font-size: 36px;
                                    font-weight: 600;
                                    line-height: 50px;
                                }

                                .subtitle {
                                    color: #998f8a;
                                    text-align: center;
                                    max-width: 710px;
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 26px;
                                }
                            }
                            .side-button-container {
                                display: none;
                            }
                        }

                        .buttons-row-container {
                            margin-top: 24px;
                            gap: 0 16px;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }

        .footer-modal {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 8px;
            padding: 16px 29px;

            .file-name {
                color: #4c4541;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
            }

            .buttons {
                display: flex;
                gap: 8px;
            }
        }

        &.animation-open-modal-text {
            animation: openModal 0.5s normal;
            @keyframes openModal {
                0% {
                    top: 100dvh;
                }
                100% {
                    top: 0;
                }
            }
        }

        &.animation-close-modal {
            animation: closeModal 0.5s normal;
            @keyframes closeModal {
                0% {
                    top: 0;
                }
                100% {
                    top: 100dvh;
                }
            }
        }
    }

    @media (max-width: 768px) and (min-width: 593px) {
        .input-modal-text-container {
            .input-person-container {
                height: calc(100dvh - 82px);
                align-items: flex-start;

                .side-button-container-extern {
                    display: none !important;
                }

                .text-input {
                    .side-button-container {
                        display: flex !important;
                    }
                }

                &.--has-photo {
                    padding-inline: 0 !important;
                    height: calc(100dvh - 190px);
                }
            }

            .footer-modal {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                padding: 16px 29px 24px;

                .file-name {
                    font-size: 26px;
                    line-height: 36px;
                }

                .buttons {
                    gap: 16px;
                    width: 100%;
                    * {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: 593px) {
        .input-modal-text-container {
            .input-person-container {
                height: calc(100dvh - 58px);
                align-items: flex-start;
                padding: 24px 16px;

                .input-person-wrapper {
                    .center-container {
                        .wrapper-container {
                            padding: 0 !important;

                            .text-input {
                                .text-content {
                                    .title {
                                        font-size: 22px;
                                        line-height: 30px;
                                    }

                                    .subtitle {
                                        font-size: 12px;
                                        line-height: 16px;
                                    }

                                    .or-div {
                                        font-size: 18px;
                                        line-height: 26px;
                                    }
                                }

                                .side-button-container {
                                    display: flex !important;
                                }
                            }
                        }
                        .buttons-row-container {
                            margin-top: 16px !important;
                        }
                    }
                }

                .side-button-container-extern {
                    display: none !important;
                }
                &.--has-photo {
                    padding-inline: 0 !important;
                    height: calc(100dvh - 160px);
                }
            }

            .footer-modal {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                padding: 16px 29px;

                .file-name {
                    font-size: 16px;
                    line-height: 26px;
                }

                .buttons {
                    gap: 16px;
                    width: 100%;
                    * {
                        width: 100%;
                    }
                }
            }
        }
    }
</style>
