<template>
    <main>
        <SelectModal
            v-if="selectModal.open"
            :title="selectModal.title()"
            :list="selectModal.data()"
            @select-item="handleSelectModalClick"
            @close="handleModal('close')"
        />

        <header class="header-operator">{{ $t("Operator.Barcode.Header") }}</header>

        <ScannedOrders
            :listOrders="listScannedOrders"
            v-if="listScannedOrders.length > 0"
        />

        <div
            class="barcode-container"
            v-else
        >
            <BarCodeIcon />
            <h4>{{ $t("Operator.Barcode.Title") }}</h4>
            <h6>{{ $t("Operator.Barcode.Subtitle") }}</h6>
        </div>

        <div
            class="float-bar"
            v-if="listScannedOrders.length > 0"
        >
            <span class="filtered-orders">
                {{ this.listScannedOrders.length }}
                {{
                    this.listScannedOrders.length > 1
                        ? $t("Operator.scannedorders")
                        : $t("Operator.ScannedOrder")
                }}
            </span>

            <div class="box-btn">
                <b-button
                    class="btn-clean"
                    @click="listScannedOrders = []"
                >
                    {{ $t("Operator.Clean") }}
                </b-button>

                <b-button
                    class="btn-point"
                    @click="redirectOption(1)"
                    v-if="showReworkButton"
                >
                    {{ $t("Operator.Barcode.Rework") }}
                </b-button>
                <b-button
                    class="btn-point"
                    @click="handleModal('open')"
                    v-else
                >
                    {{ $t("Operator.Point") }} <ArrowDownIcon />
                </b-button>
            </div>
        </div>
    </main>
</template>

<script>
    import { mapActions, mapMutations } from "vuex";
    import { BFormInput, BButton } from "bootstrap-vue";
    import { mapGetters } from "vuex";
    import BarCodeIcon from "@/assets/images/icons/barcode-icon.svg";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import SelectModal from "./components/SelectModal/SelectModal.vue";
    import ArrowDownIcon from "@/@core/assets/icons/arrow-down.svg";

    export default {
        components: {
            BarCodeIcon,
            BFormInput,
            BButton,
            ToastificationContent,
            SelectModal,
            ArrowDownIcon,
            ScannedOrders: () => import("./ScannedOrders.vue")
        },
        data() {
            return {
                currentSite: this.$cookies.get("userInfo").currentSite,
                prefix: localStorage.getItem("prefix") || this.$router.currentRoute.params.prefix,
                barcode: null,
                listScannedOrders: [],
                timeout: [],
                setTimeout: true,
                localeOpen: false,
                showReworkButton: false,
                cleanBarCodeTimeout: null,
                selectModal: {
                    open: false,
                    title: () => this.$t("Operator.SelectAppointmentType.Title"),
                    data: () => [
                        {
                            id: 0,
                            name: this.$t("Operator.SelectAppointmentType.Items.Productive"),
                            redirect: () => this.redirectOption(0)
                        },
                        {
                            id: 1,
                            name: this.$t("Operator.SelectAppointmentType.Items.Rework"),
                            redirect: () => this.redirectOption(1)
                        }
                    ]
                }
            };
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapActions("app", ["removeRequestLoading"]),
            handleSelectModalClick(value) {
                value.item.redirect();
            },
            handleModal(action) {
                this.selectModal.open = action === "open";
            },
            redirectOption(appointment_type) {
                const isMultipleOperations = this.listScannedOrders.length > 1;

                if (
                    !this.listScannedOrders.length ||
                    !this.listScannedOrders?.[0]?.resource?.length
                ) {
                    return;
                }

                const routerParams = {
                    name: isMultipleOperations
                        ? "operator-production-progress-multiple"
                        : "operator-production-progress",
                    params: {
                        prefix: this.prefix,
                        resource_id: this.listScannedOrders[0]?.resource?.[0]?.id ?? null,
                        multiple: isMultipleOperations.toString(),
                        orders: isMultipleOperations ? this.listScannedOrders : [],
                        appointment_type
                    }
                };

                if (isMultipleOperations) {
                    routerParams.params.preactor_orders = this.listScannedOrders
                        .map((item) => item.operation.id)
                        .join(",");
                }

                if (!isMultipleOperations) {
                    routerParams.params.return_orders = "true";
                    routerParams.params.order_id = this.listScannedOrders[0].operation.id;
                }

                this.$router.push(routerParams);
            },
            readBarcode() {
                if (this.barcode.length) {
                    this.getOrders(this.barcode);
                    this.$nextTick(() => {
                        this.barcode = "";
                    });
                }
            },
            async getOrders(operationNumber) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                const GET_OPERATION_URL = `/api/mysfc/operator/order?siteId=${this.currentSite}&completedOrders=false&filter=${operationNumber}`;

                try {
                    const response = await this.$http2.get(GET_OPERATION_URL);
                    if (response.data.data && response.data.data.length > 0) {
                        this.validateOperation(response.data.data[0]);
                        return;
                    }

                    this.showToast(
                        this.$t("RefuseStatus.Error"),
                        "FrownIcon",
                        this.$t("Operator.ErrorOrderNotFound"),
                        "danger"
                    );
                } finally {
                    this.removeRequestLoading();
                }
            },
            validateOperation(operation) {
                if (this.listScannedOrders.length == 0) {
                    this.listScannedOrders.push(operation);
                    this.showReworkButton = operation.operation.isRework;
                    return;
                }

                const firstScannedOrder = this.listScannedOrders[0];
                const existOrder = this.listScannedOrders.find(
                    (f) => f.operation.id == operation.operation.id
                );

                if (existOrder) return;

                if (firstScannedOrder.workcenter.controlByHour)
                    return this.showToast(
                        this.$t("RefuseStatus.Error"),
                        "FrownIcon",
                        this.$t("Operator.ErrorOrderByHour"),
                        "danger"
                    );

                if (
                    operation.operation.statusId != firstScannedOrder.operation.statusId &&
                    !operation.workcenter.controlByHour
                )
                    return this.showToast(
                        this.$t("RefuseStatus.Error"),
                        "FrownIcon",
                        this.$t("Operator.ErrorOrderDiffStatus"),
                        "danger"
                    );
                if (operation.workcenter.controlByHour)
                    return this.showToast(
                        this.$t("RefuseStatus.Error"),
                        "FrownIcon",
                        this.$t("Operator.ErrorOrderDiffType"),
                        "danger"
                    );
                if (firstScannedOrder.operation.isRework && !operation.operation.isRework)
                    return this.showToast(
                        this.$t("RefuseStatus.Error"),
                        "FrownIcon",
                        this.$t("Operator.ErrorOrderProductive"),
                        "danger"
                    );
                if (!firstScannedOrder.operation.isRework && operation.operation.isRework)
                    return this.showToast(
                        this.$t("RefuseStatus.Error"),
                        "FrownIcon",
                        this.$t("Operator.ErrorOrderRework"),
                        "danger"
                    );

                this.listScannedOrders.push(operation);
            },
            handleKeydown(event) {
                const { key } = event;

                if (key.length === 1) this.barcode = this.barcode ? this.barcode + key : key;
                event.key === "Enter" && this.readBarcode();
            },
            clearBarCode() {
                this.barcode = "";
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            }
        },
        mounted() {
            window.addEventListener("keydown", this.handleKeydown);
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.handleKeydown);

            this.$root.$off("bv::dropdown::show");
            this.$root.$off("bv::dropdown::hide");
        },
        computed: {
            ...mapGetters("app", ["flagShowModalPin"])
        },
        watch: {
            flagShowModalPin(value) {
                if (value) {
                    window.removeEventListener("keydown", this.handleKeydown);
                    this.$root.$off("bv::dropdown::show");
                    this.$root.$off("bv::dropdown::hide");

                    return;
                }

                window.addEventListener("keydown", this.handleKeydown);
            },
            barcode(v) {
                clearTimeout(this.cleanBarCodeTimeout);

                if (v) this.cleanBarCodeTimeout = setTimeout(() => this.clearBarCode(), 1000);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .header-operator {
        border-top: 1px solid #cfc4be;
        margin-top: -48px !important;
        padding: 16px 29px;
        z-index: 1;
    }

    main {
        width: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }

    header {
        width: 100vw;
        position: fixed;
        margin-top: -44px;

        display: flex;
        align-items: center;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;

        background: #ffffff;
        color: #4c4541;
        box-shadow: 0px 8px 30px rgba(58, 46, 38, 0.1);
    }

    .barcode-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 310px;

        svg {
            margin-bottom: 37px;
            margin-top: -120px;
            width: 80px;
            height: 54px;
        }

        h4,
        h6 {
            font-family: "Raleway";
            font-style: normal;
            text-align: center;
        }

        h4 {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #4c4541;
            margin-bottom: 8px !important;
        }

        h6 {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #998f8a;
        }
    }

    .float-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 16px;

        width: 563px;
        height: 70px;

        background: #ffffff;
        border: 1px solid #cfc4be;
        box-shadow:
            0px 6px 10px rgba(0, 0, 0, 0.14),
            0px 1px 18px rgba(0, 0, 0, 0.12),
            0px 3px 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;

        position: fixed;
        bottom: 71.5px;
        z-index: 11;
        font-size: 12px;
        outline: none;
        right: 27px;

        .filtered-orders {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            font-feature-settings:
                "pnum" on,
                "lnum" on;
            color: #4c4541;
            margin-right: auto;
        }
    }

    .btn-point {
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 23px !important;
        color: #ffffff !important;
        border-color: #974900 !important;
        background-color: #974900 !important;
        border-radius: 5px !important;
        height: 38px !important;
        padding: 6px 20px;
        letter-spacing: 0.175px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        svg {
            height: 14px;
            width: 14px;
        }

        &:hover {
            box-shadow: none !important;
        }
    }

    .btn-clean {
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.175px;
        color: #974900 !important;
        background-color: #ffffff !important;
        border-radius: 5px;
        height: 38px !important;
        padding: 6px 20px;
        border: 1px solid #974900 !important;

        &:hover {
            background-color: #ffede1 !important;
            box-shadow: none !important;
        }
    }

    .box-btn {
        display: flex;
        gap: 15px;
        justify-content: end;
    }

    @media screen and (max-width: 768px) {
        header {
            font-size: 36px;
            line-height: 50px;
            padding: 16px 29px;
            margin-top: -50px;
        }

        .float-bar {
            right: auto;
            left: auto;
            width: calc(100% - 2.4rem);
            height: 78px;

            .filtered-orders {
                font-size: 18px;
            }
        }

        .btn-clean {
            height: 46px !important;
        }

        .btn-point {
            height: 46px !important;
            line-height: 33px !important;
        }
    }

    @media screen and (max-width: 593px) {
        .float-bar {
            flex-direction: column;
            align-items: center;
            height: 120px;
            padding: 16px;

            .filtered-orders {
                margin-right: initial;
                font-size: 18px;
            }

            .btn-clean {
                height: 46px !important;
                width: 100% !important;
                font-size: 17px !important;
                padding: 13px 28px;
            }

            .btn-point {
                height: 46px !important;
                width: 100% !important;
                font-size: 17px !important;
                padding: 13px 28px;
                line-height: 20px !important;

                svg {
                    width: 16px !important;
                    height: 16px !important;
                }
            }

            .box-btn {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 575px) {
        .header-operator {
            padding: 16px !important;
        }
    }

    @media screen and (max-width: 480px) {
        .header-operator {
            border-top: 1px solid #cfc4be;
        }
    }

    @media screen and (max-width: 425px) {
        header {
            font-size: 18px;
            line-height: 26px;
        }

        .barcode-container {
            h4 {
                font-size: 16px;
                line-height: 26px;
            }

            h6 {
                font-size: 12px;
                line-height: 16px;
            }

            svg {
                margin-top: -220px !important;
            }
        }

        .float-bar {
            height: 96px;

            .filtered-orders {
                margin-right: initial;
                font-size: 14px;
            }

            .btn-clean {
                height: 28px !important;
                width: 100% !important;
                font-size: 12px !important;
                padding: 2px 14px;
            }

            .btn-point {
                height: 28px !important;
                width: 100% !important;
                font-size: 12px !important;
                padding: 2px 14px;
                line-height: 23px !important;

                svg {
                    width: 12px !important;
                    height: 12px !important;
                }
            }
        }
    }
</style>
