<template>
    <div class="orders-group-material-modal-content__wrapper">
        <div class="orders-group-material-modal-content__header">
            <div class="orders-group-material-modal-content__consumed-total-container">
                <span class="orders-group-material-modal-content__consumed-total-label">
                    {{ $t("ProductionProgress.TotalConsumed") }}
                </span>

                <div class="orders-group-material-modal-content__input-box">
                    <QuantityConsumedInput
                        :value="ordersGroupMaterialModal.material.totalConsumed"
                        :formatter="handleFormatterQuantityConsumed"
                        :state="ordersGroupMaterialModal.material.valid"
                        @input="updateConsumedQuantity"
                        @increment="handleIncrementAndDescrement('increment')"
                        @decrement="handleIncrementAndDescrement('decrement')"
                        @blur="validateQuantityConsumed"
                    />
                </div>
            </div>

            <div class="orders-group-material-modal-content__columns">
                <span class="orders-group-material-modal-content__column-label">
                    {{ $t("ProductionProgress.Order") }}
                </span>
                <span class="orders-group-material-modal-content__column-label">
                    {{ $t("ProductionProgress.ConsumedQuantity") }}
                </span>
            </div>
        </div>

        <div class="orders-group-material-modal-content__container">
            <div class="orders-group-material-modal-content__orders">
                <Material
                    v-for="(operation, index) in ordersGroupMaterialModal.material.operations"
                    :key="`operation-${index}`"
                    :operation="operation"
                    :isEven="index % 2 === 0"
                    @removeOperation="removeOperation"
                />
            </div>
        </div>
        <div class="orders-group-material-content__footer">
            <div class="orders-group-material-content__footer-content-right">
                <sys-button
                    class="orders-group-material-content__remove-material-button"
                    scheme="square"
                    variant="outline"
                    status="danger"
                    :size="handleFooterButtonSize()"
                    :full="windowWidth <= 768"
                    @click="handleOpenRemoveMaterialModal"
                >
                    {{ $t("ProductionProgress.RemoveMaterial") }}
                </sys-button>
                <sys-button
                    scheme="square"
                    variant="contained"
                    :size="handleFooterButtonSize()"
                    :full="windowWidth <= 768"
                    :disabled="!ordersGroupMaterialModal.hasChanges"
                    @click="saveChanges"
                >
                    {{ $t("ProductionProgress.SaveChanges") }}
                </sys-button>
            </div>
        </div>
    </div>
</template>

<script>
    import SysButton from "@/@core/components/sys-button/index.vue";
    import Material from "./Material.vue";
    import { mapGetters, mapMutations, mapActions } from "vuex";
    import QuantityConsumedInput from "./QuantityConsumedInput.vue";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
    import {
        formatCurrencyWithoutSymbol,
        handleFormatQuantityConsumedToNumber,
        handleConsumedRequired
    } from "../../utils";
    import { filterMaterials } from "../RecordMaterialsModal/utils";

    export default {
        components: { SysButton, Material, QuantityConsumedInput },
        computed: {
            ...mapGetters("production_progress", [
                "ordersGroupMaterialModal",
                "recordMaterialsModal"
            ]),
            ...mapGetters("app", ["windowWidth"])
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("production_progress", [
                "UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL",
                "UPDATE_ORDERS_GROUP_MATERIAL_MODAL_OPERATIONS_MATERIAL",
                "UPDATE_ORDERS_GROUP_MATERIAL_MODAL_HAS_CHANGES",
                "UPDATE_RECORD_MATERIALS_MODAL_MATERIALS",
                "UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST",
                "UPDATE_RECORD_MATERIALS_MODAL_HISTORY"
            ]),
            ...mapActions("production_progress", [
                "backToRecordMaterialsModal",
                "closeRecordMaterialsModal"
            ]),
            handleFooterButtonSize() {
                if (this.windowWidth > 768) {
                    return "md";
                }

                if (this.windowWidth > 570 && this.windowWidth <= 768) {
                    return "xl";
                }

                return "xs";
            },
            formatCurrencyWithoutSymbol(value) {
                const numberValue = this.handleFormatQuantityConsumedToNumber(value);

                let formattedValue = new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numberValue);

                return formattedValue;
            },
            handleFormatterQuantityConsumed(value) {
                const hasMaterial = !!this.ordersGroupMaterialModal.material;

                if (!hasMaterial || value === "0,0" || !value) return "";

                return this.formatCurrencyWithoutSymbol(value);
            },
            async removeOperation(operationId) {
                const material = this.ordersGroupMaterialModal.material;
                this.$swal({
                    title: this.$t("IntegrationPage.attention"),
                    text:
                        this.$t("ProductionProgress.RemoveNoteAlertPart1") +
                        (material.name || "") +
                        this.$t("ProductionProgress.MultipleRemoveNoteAlertPart2"),
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("MySites.cancel"),
                    confirmButtonText: this.$t("ProductionProgress.Remove"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (!result.value) return;

                    this.handleRemoveMaterialIndividual(material, operationId);
                });
            },
            async handleGetMaterialsHistoryMultiple() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.getMaterialsHistoryMultiple();

                    this.UPDATE_RECORD_MATERIALS_MODAL_HISTORY([...response]);
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async getMaterialsHistoryMultiple() {
                const { preactor_orders } = this.$router.currentRoute.params;
                const GET_MATERIALS_HISTORY_MULTIPLE_URL = `/api/mysfc/operation/multiple/materials-history?operationIds=${preactor_orders ?? ""}`;

                try {
                    const response = await this.$http2.get(GET_MATERIALS_HISTORY_MULTIPLE_URL);

                    const formattedMaterialsHistory = [];

                    response?.data?.data?.forEach((material) => {
                        const { history, ...materialRest } = material;
                        material?.history?.forEach((history) => {
                            formattedMaterialsHistory.push({
                                ...materialRest,
                                ...history
                            });
                        });
                    });

                    return Promise.resolve(formattedMaterialsHistory);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            async handleRemoveMaterialIndividual(materialParam, operationId) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await this.removeMaterial(materialParam, operationId);
                    await this.handleGetMaterialsHistoryMultiple();
                    const materialsResponse = await this.handleGetMaterialsMultiple();

                    const localOperations = [...this.ordersGroupMaterialModal.material.operations];
                    const localMaterial = materialsResponse?.find(
                        (item) => item.id === this.ordersGroupMaterialModal.material.id
                    );

                    if (!localMaterial?.operations?.length) {
                        this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({});
                        this.backToRecordMaterialsModal();
                        return;
                    }

                    localMaterial.operations = this.handlePersistFilledData(
                        localOperations,
                        localMaterial.operations
                    );

                    localMaterial.totalConsumed = this.handleCalcTotalConsumed(localMaterial);

                    this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({ ...localMaterial });
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            handlePersistFilledData(oldList, newList) {
                const formattedNewList =
                    newList?.map((newMaterial) => {
                        const itemInOldList = oldList?.find(
                            (oldMaterial) => oldMaterial.id === newMaterial.id
                        );

                        if (itemInOldList) return { ...itemInOldList };

                        return newMaterial;
                    }) || [];

                return formattedNewList;
            },
            validateQuantityConsumed() {
                const materialLocal = {
                    ...this.ordersGroupMaterialModal.material
                };

                if (!materialLocal) return;

                if (!this.ordersGroupMaterialModal.material.quantityConsumed) {
                    materialLocal.valid = false;
                } else {
                    materialLocal.valid = true;
                }

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });
            },
            updateConsumedQuantity(value) {
                const materialLocal = { ...this.ordersGroupMaterialModal.material };

                if (!materialLocal) {
                    return;
                }

                materialLocal.totalConsumed = value;

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });

                this.handleRecalcOperations(materialLocal);

                if (!this.ordersGroupMaterialModal.hasChanges) {
                    this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_HAS_CHANGES(true);
                }
            },
            handleFormatQuantityConsumedToNumber(value) {
                const replacedValue = value.replace(/[^\d]/g, "");

                const onlyDigits = replacedValue
                    .split("")
                    .filter((s) => /\d/.test(s))
                    .join("")
                    .padStart(3, "0");

                if (isNaN(onlyDigits)) {
                    return;
                }

                const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);

                return Number(digitsFloat);
            },
            handleIncrementAndDescrement(operation = "increment") {
                const materialLocal = { ...this.ordersGroupMaterialModal.material };

                const totalConsumed = this.handleFormatQuantityConsumedToNumber(
                    materialLocal.totalConsumed
                );

                let newTotalConsumed =
                    operation === "increment" ? totalConsumed + 1 : totalConsumed - 1;

                if (newTotalConsumed < 0) {
                    newTotalConsumed = 0;
                }

                const formattedNewTotalConsumed = new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(newTotalConsumed);

                materialLocal.totalConsumed = formattedNewTotalConsumed;

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });

                if (!this.ordersGroupMaterialModal.hasChanges) {
                    this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_HAS_CHANGES(true);
                }

                this.handleRecalcOperations(materialLocal);
                this.validateOperations();
            },
            handleRecalcOperations(material) {
                const materialLocal = { ...material };
                const quantityOperations = materialLocal.operations.length;
                const totalConsumedNumber = this.handleFormatQuantityConsumedToNumber(
                    materialLocal.totalConsumed
                );

                const baseValue =
                    Math.floor((totalConsumedNumber / quantityOperations) * 100) / 100; // Arredonda para baixo com duas casas decimais

                const valuesSum = baseValue * quantityOperations;

                const diff = parseFloat((totalConsumedNumber - valuesSum).toFixed(2));

                const formattedOperations = materialLocal.operations.map((operation, index) => {
                    if (index === quantityOperations - 1) {
                        return {
                            ...operation,
                            quantityConsumed: new Intl.NumberFormat("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }).format(baseValue + diff)
                        };
                    }

                    return {
                        ...operation,
                        quantityConsumed: new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(baseValue)
                    };
                });

                materialLocal.operations = formattedOperations;

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });
            },
            validateOperations() {
                let isValid = true;

                const materialLocal = { ...this.ordersGroupMaterialModal.material };

                if (!materialLocal) return false;

                if (!this.ordersGroupMaterialModal.material.totalConsumed) {
                    materialLocal.valid = false;
                    isValid = false;
                } else {
                    materialLocal.valid = true;
                }

                materialLocal.operations.forEach((operation) => {
                    if (
                        (!operation.quantityConsumed && operation.required) ||
                        !handleFormatQuantityConsumedToNumber(operation.quantityConsumed)
                    ) {
                        operation.valid = false;
                        isValid = false;
                    } else {
                        operation.valid = true;
                    }
                });

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });

                return isValid;
            },
            validateFields() {
                const operationsValidation = this.validateOperations();

                return operationsValidation;
            },
            handleOpenRemoveMaterialModal() {
                const material = this.ordersGroupMaterialModal.material;
                this.$swal({
                    title: this.$t("IntegrationPage.attention"),
                    text:
                        this.$t("ProductionProgress.RemoveNoteAlertPart1") +
                        (material.name || "") +
                        this.$t("ProductionProgress.RemoveNoteAlertPart2"),
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("MySites.cancel"),
                    confirmButtonText: this.$t("ProductionProgress.Remove"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (!result.value) return;

                    this.handleRemoveMaterial(material);
                });
            },
            async handleRemoveMaterial(material) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.removeMaterial(material);
                    await this.handleGetMaterialsHistoryMultiple();
                    const materialsResponse = await this.getMaterialsMultiple();

                    const newMaterials = materialsResponse;

                    this.UPDATE_RECORD_MATERIALS_MODAL_MATERIALS([...newMaterials]);
                    filterMaterials(
                        this.recordMaterialsModal.filterInput,
                        newMaterials,
                        this.UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST
                    );
                    this.backToRecordMaterialsModal();
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async removeMaterial(material, idOperation = null) {
                const DELETE_MATERIAL_URL = `/api/mysfc/operation/multiple/material/${material.id}/remove`;
                const operationsIds = material.operations.map((e) => e.id);
                let payload = operationsIds;

                if (idOperation) {
                    payload = [idOperation];
                }

                try {
                    const response = await this.$http2.delete(DELETE_MATERIAL_URL, {
                        data: payload
                    });
                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        this.$t("ProductionProgress.MaterialRemoved"),
                        "success"
                    );
                    return Promise.resolve(response);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            async getMaterialsMultiple() {
                const { preactor_orders } = this.$router.currentRoute.params;
                const GET_MATERIALS_URL = `/api/mysfc/operation/multiple/materials?operationIds=${preactor_orders}`;
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_MATERIALS_URL);

                    const formattedMaterials =
                        response?.data?.data?.map((material) => {
                            const newMaterial = {
                                ...material,
                                totalConsumed: formatCurrencyWithoutSymbol(material.totalConsumed),
                                operations: material.operations.map((e) => ({
                                    ...e,
                                    quantityConsumed: formatCurrencyWithoutSymbol(
                                        handleConsumedRequired(
                                            e.quantityRequired,
                                            e.quantityConsumed,
                                            e.required
                                        )
                                    ),
                                    valid: null
                                }))
                            };

                            newMaterial.totalConsumed = this.handleCalcTotalConsumed(newMaterial);

                            return newMaterial;
                        }) || [];

                    return Promise.resolve(formattedMaterials);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            handleCalcTotalConsumed(material) {
                const localMaterial = { ...material };

                const totalConsumed = localMaterial.operations.reduce((acc, operation) => {
                    const quantityConsumed = this.handleFormatQuantityConsumedToNumber(
                        operation.quantityConsumed
                    );

                    return acc + quantityConsumed;
                }, 0);

                const formattedTotalConsumed = new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(totalConsumed);

                return formattedTotalConsumed;
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        variant
                    }
                });
            },
            async handleGetMaterialsMultiple() {
                try {
                    const materialsResponse = await this.getMaterialsMultiple();

                    this.UPDATE_RECORD_MATERIALS_MODAL_MATERIALS([...materialsResponse]);
                    filterMaterials(
                        this.recordMaterialsModal.filterInput,
                        materialsResponse,
                        this.UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST
                    );

                    return Promise.resolve(materialsResponse);
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async saveChanges() {
                const validationResult = this.validateFields();

                if (!validationResult) {
                    return;
                }

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await this.saveQuantityConsumed();
                    this.handleGetMaterialsMultiple();
                    this.backToRecordMaterialsModal();
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            handleCreatePayloadToSaveQuantityConsumed() {
                const materialsListLocal = this.recordMaterialsModal.materials[0];
                const formattedOperationsList = materialsListLocal.operations.map((operation) => ({
                    operationId: operation.id,
                    quantityConsumed: operation.quantityConsumed
                        ? handleFormatQuantityConsumedToNumber(operation.quantityConsumed)
                        : null
                }));

                return formattedOperationsList;
            },
            async saveQuantityConsumed() {
                const material = this.ordersGroupMaterialModal.material;
                const PUT_CONSUMED_MATERIALS_URL = `api/mysfc/operation/multiple/material/${material.id}/quantity-consumed`;
                const payload = this.handleCreatePayloadToSaveQuantityConsumed();

                try {
                    const response = await this.$http2.put(PUT_CONSUMED_MATERIALS_URL, payload);

                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        this.$t("ProductionProgress.MaterialsUpdated"),
                        "success"
                    );

                    return Promise.resolve(response);
                } catch (error) {
                    console.error(error);

                    return Promise.reject(error);
                }
            },
            validateQuantityConsumed() {
                const materialLocal = {
                    ...this.ordersGroupMaterialModal.material
                };

                if (!materialLocal) return;

                if (!this.ordersGroupMaterialModal.material.totalConsumed) {
                    materialLocal.valid = false;
                } else {
                    materialLocal.valid = true;
                }

                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...materialLocal
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    #orders-group-material-modal {
        .orders-group-material-modal-content__wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            .orders-group-material-modal-content__container {
                flex-grow: 1;
                height: 100%;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-track {
                    background: #fff;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #cfc4be;
                    border-radius: 9px;
                }
            }

            .orders-group-material-modal-content__header {
                width: 100%;
                position: sticky;
                top: 0;
                left: 0;

                .orders-group-material-modal-content__consumed-total-container {
                    padding: 23.5px 16px;
                    border-bottom: 1px solid #cfc4be;
                    display: flex;
                    align-items: center;

                    .orders-group-material-modal-content__consumed-total-label {
                        color: #4c4541;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                        width: 100%;
                    }

                    .orders-group-material-modal-content__input-box {
                        min-width: 242px;
                        max-width: 242px;
                    }
                }

                .orders-group-material-modal-content__columns {
                    display: flex;
                    flex-wrap: nowrap;
                    gap: 8px;
                    padding: 8px 0;

                    .orders-group-material-modal-content__column-label {
                        color: #998f8a;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;

                        &:first-child {
                            padding-left: 70px;
                            width: 100%;
                        }

                        &:last-child {
                            min-width: 258px;
                            max-width: 258px;
                        }
                    }
                }
            }

            .orders-group-material-content__footer {
                position: sticky;
                bottom: 0;
                left: 0;

                width: 100%;
                border-top: 1px solid #cfc4be;
                padding: 15.5px 29px;

                .orders-group-material-content__footer-content-right {
                    width: fit-content;
                    margin-left: auto;
                    display: flex;
                    gap: 16px;
                }
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #orders-group-material-modal {
            .orders-group-material-modal-content__wrapper {
                .orders-group-material-modal-content__header {
                    .orders-group-material-modal-content__consumed-total-container {
                        padding: 23.5px 29px !important;
                        .orders-group-material-modal-content__consumed-total-label {
                            font-size: 22px !important;
                            line-height: 30px !important;
                        }
                    }

                    .orders-group-material-modal-content__columns {
                        .orders-group-material-modal-content__column-label {
                            font-size: 16px !important;
                            line-height: 26px !important;

                            &:first-child {
                                padding-left: 91px !important;
                            }
                        }
                    }
                }

                .orders-group-material-content__footer {
                    .orders-group-material-content__footer-content-right {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    @media (max-width: 570px) {
        #orders-group-material-modal {
            .orders-group-material-modal-content__wrapper {
                .orders-group-material-modal-content__header {
                    .orders-group-material-modal-content__consumed-total-container {
                        flex-direction: column;
                        gap: 16px;
                        .orders-group-material-modal-content__consumed-total-label {
                            font-size: 16px !important;
                            line-height: 26px !important;
                        }

                        .orders-group-material-modal-content__input-box {
                            min-width: 100%;
                            max-width: 100%;
                        }
                    }

                    .orders-group-material-modal-content__columns {
                        display: none;
                    }
                }

                .orders-group-material-content__footer {
                    padding: 15.5px 16px !important;

                    .orders-group-material-content__footer-content-right {
                        width: 100% !important;

                        .orders-group-material-content__remove-material-button {
                            display: none;
                        }
                    }
                }
            }
        }
    }
</style>
