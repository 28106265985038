<template>
    <div
        :class="[
            'orders-group-material-modal',
            {
                '--open': open
            }
        ]"
    >
        <b-sidebar
            id="orders-group-material-modal"
            :visible="open"
            shadow
            right
            bg-variant="white"
            backdrop
            no-close-on-backdrop
            no-close-on-esc
        >
            <template #header>
                <Header />
            </template>

            <template>
                <Content />
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { BSidebar, VBToggle } from "bootstrap-vue";
    import Header from "./Header.vue";
    import Content from "./Content.vue";
    import { mapActions } from "vuex";

    export default {
        components: {
            Header,
            Content,
            BSidebar
        },
        directives: {
            "b-toggle": VBToggle
        },
        props: {
            open: {
                type: Boolean,
                required: true,
                default: true
            }
        },
        methods: {
            ...mapActions("production_progress", ["closeOrdersGroupMaterialModal"])
        }
    };
</script>

<style lang="scss">
    body:has(.orders-group-material-modal.--open) {
        overflow: hidden;
    }

    #orders-group-material-modal {
        min-width: 600px;
        max-width: 600px;
        max-height: 100dvh;
    }

    @media (max-width: 768px) {
        #orders-group-material-modal {
            min-width: 100dvw;
            max-width: 100dvw;
        }
    }
</style>
