<template>
    <div class="operations-modal-footer__container">
        <div class="operations-modal-footer__content-left">
            <span
                class="operations-modal-footer__quantity"
                v-if="operationsModal.selectedOperationIds.length"
            >
                {{ operationsModal.selectedOperationIds.length }}
                {{ $t("ProductionProgress.SelectedOrders") }}
            </span>
            <span
                class="operations-modal-footer__quantity"
                v-else
            >
                {{ $t("ProductionProgress.NoOrdersSelected") }}
            </span>
        </div>
        <div class="operations-modal-footer__content-right">
            <sys-button
                @click="cancel()"
                scheme="square"
                :size="handleButtonSize()"
                variant="outline"
                :full="windowWidth <= 570"
            >
                {{ $t("ProductionProgress.Cancel") }}
            </sys-button>

            <sys-button
                @click="addOperations()"
                scheme="square"
                :size="handleButtonSize()"
                variant="contained"
                :full="windowWidth <= 570"
                :disabled="!operationsModal.selectedOperationIds.length"
            >
                {{ $t("ProductionProgress.Add2") }}
            </sys-button>
        </div>
    </div>
</template>

<script>
    import SysButton from "@/@core/components/sys-button";
    import { mapGetters, mapActions, mapMutations } from "vuex";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
    import { filterMaterials } from "../RecordMaterialsModal/utils";
    import {
        formatCurrencyWithoutSymbol,
        handleConsumedRequired,
        handleFormatQuantityConsumedToNumber
    } from "@/views/pages/operator/utils";

    export default {
        components: {
            SysButton
        },
        computed: {
            ...mapGetters("app", ["windowWidth"]),
            ...mapGetters("production_progress", ["operationsModal", "recordMaterialsModal"])
        },
        methods: {
            ...mapActions("production_progress", ["backToAddMaterialsModal"]),
            ...mapMutations("production_progress", [
                "UPDATE_RECORD_MATERIALS_MODAL_MATERIALS",
                "UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST",
                "UPDATE_ADD_MATERIAL_MODAL_SELECTED_MATERIAL_IDS"
            ]),
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            handleButtonSize() {
                if (this.windowWidth >= 768) return "md";
                if (this.windowWidth < 768 && this.windowWidth > 570) return "xl";
                return "xs";
            },

            async addOperations() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.addMaterials();
                    this.UPDATE_ADD_MATERIAL_MODAL_SELECTED_MATERIAL_IDS([]);

                    const materialsResponse = await this.getMaterialsMultiple();

                    const newMatarials = materialsResponse;
                    this.UPDATE_RECORD_MATERIALS_MODAL_MATERIALS([...newMatarials]);
                    filterMaterials(
                        this.recordMaterialsModal.filterInput,
                        newMatarials,
                        this.UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST
                    );

                    this.backToAddMaterialsModal();
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        variant
                    }
                });
            },
            async getMaterialsMultiple() {
                const { preactor_orders } = this.$router.currentRoute.params;
                const GET_MATERIALS_URL = `/api/mysfc/operation/multiple/materials?operationIds=${preactor_orders}`;
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_MATERIALS_URL);

                    const formattedMaterials =
                        response?.data?.data?.map((material) => {
                            const newMaterial = {
                                ...material,
                                totalConsumed: formatCurrencyWithoutSymbol(material.totalConsumed),
                                operations: material.operations.map((e) => ({
                                    ...e,
                                    quantityConsumed: formatCurrencyWithoutSymbol(
                                        handleConsumedRequired(
                                            e.quantityRequired,
                                            e.quantityConsumed,
                                            e.required
                                        )
                                    ),
                                    valid: null
                                }))
                            };

                            newMaterial.totalConsumed = this.handleCalcTotalConsumed(newMaterial);

                            return newMaterial;
                        }) || [];

                    return Promise.resolve(formattedMaterials);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            handleCalcTotalConsumed(material) {
                const localMaterial = { ...material };

                const totalConsumed = localMaterial.operations.reduce((acc, operation) => {
                    const quantityConsumed = handleFormatQuantityConsumedToNumber(
                        operation.quantityConsumed
                    );

                    return acc + quantityConsumed;
                }, 0);

                const formattedTotalConsumed = new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(totalConsumed);

                return formattedTotalConsumed;
            },
            async addMaterials() {
                const POST_ADD_MATERIALS_URL = `api/mysfc/operation/multiple/material/${this.operationsModal.material.id}/add-material`;

                const payload = this.operationsModal.selectedOperationIds;

                try {
                    const response = await this.$http2.post(POST_ADD_MATERIALS_URL, payload);

                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        this.$t("ProductionProgress.MaterialsAdded"),
                        "success"
                    );

                    return Promise.resolve(response);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            cancel() {
                this.backToAddMaterialsModal();
            }
        }
    };
</script>

<style lang="scss" scoped>
    #operations-modal {
        .operations-modal-footer__container {
            display: flex;
            align-items: center;
            padding: 15.5px 29px;
            gap: 16px;
            border-top: 1px solid#CFC4BE;

            .operations-modal-footer__content-left {
                display: flex;
                .operations-modal-footer__quantity {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }

            .operations-modal-footer__content-right {
                margin-left: auto;
                display: flex;
                gap: 16px;
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #operations-modal {
            .operations-modal-footer__container {
                .operations-modal-footer__content-left {
                    .operations-modal-footer__quantity {
                        font-size: 22px !important;
                        line-height: 30px !important;
                    }
                }
            }
        }
    }

    @media (max-width: 570px) {
        #operations-modal {
            .operations-modal-footer__container {
                justify-content: center;
                flex-direction: column !important;
                align-items: center;
                padding: 15.5px 16px;

                .operations-modal-footer__content-left {
                    justify-content: center;
                    .operations-modal-footer__quantity {
                        font-size: 12px !important;
                        line-height: 16px !important;
                    }
                }

                .operations-modal-footer__content-right {
                    gap: 8px !important;
                    margin: 0 !important;
                    width: 100%;
                }
            }
        }
    }
</style>
