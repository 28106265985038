<template>
    <div
        :class="[
            'operations-modal',
            {
                '--open': open
            }
        ]"
    >
        <b-sidebar
            id="operations-modal"
            :visible="open"
            shadow
            right
            bg-variant="white"
            backdrop
            no-close-on-backdrop
            no-close-on-esc
        >
            <template #header>
                <Header />
            </template>

            <template>
                <Content />
            </template>

            <template #footer>
                <Footer />
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { BSidebar, VBToggle } from "bootstrap-vue";
    import Content from "./Content.vue";
    import Header from "./Header.vue";
    import Footer from "./Footer.vue";

    export default {
        components: {
            BSidebar,
            Content,
            Header,
            Footer
        },
        directives: {
            "b-toggle": VBToggle
        },
        computed: {},
        props: {
            open: {
                type: Boolean,
                required: true,
                default: true
            }
        },
        methods: {}
    };
</script>

<style lang="scss">
    body:has(.operations-modal.--open) {
        overflow: hidden;
    }

    #operations-modal {
        min-width: 600px;
        max-width: 600px;
        max-height: 100dvh;
    }

    @media (max-width: 768px) {
        #operations-modal {
            min-width: 100dvw;
            max-width: 100dvw;
        }
    }
</style>
