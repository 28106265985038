<template>
    <div class="container-operator-orders">
        <div
            class="operator-orders-header"
            :style="
                !showFilter
                    ? 'box-shadow: 0px 8px 30px rgba(58, 46, 38, 0.1);'
                    : 'border-bottom: 1px solid #CFC4BE'
            "
        >
            <div
                class="ibox-filter"
                v-if="showFilter && !filterByResource"
            >
                <div
                    class="btn-search"
                    @click="closeFilter"
                >
                    <BackIconBigger v-if="biggerIcon" />
                    <BackIcon v-else />
                </div>

                <div class="header-divider"></div>

                <input
                    type="number"
                    v-show="showOperations"
                    :placeholder="$t('Operator.SearchOrders')"
                    v-model="textFilter"
                    @keyup.enter="getOperations(null)"
                    ref="inputOrders"
                    @focus="setInputFocusLog('inputOrders', true)"
                    @blur="setInputFocusLog('inputOrders', false)"
                />

                <input
                    type="text"
                    v-show="showResources"
                    :placeholder="$t('Operator.SearchResources')"
                    v-model="textResource"
                    @keyup.enter="getResources(null)"
                    ref="inputResources"
                    @focus="setInputFocusLog('inputResources', true)"
                    @blur="setInputFocusLog('inputResources', false)"
                />

                <input
                    type="number"
                    class="input-search-finished"
                    v-show="showFinished"
                    :placeholder="$t('Operator.SearchFinished')"
                    v-model="textFinished"
                    @keyup.enter="getFinishedOperations(null)"
                    ref="inputFinished"
                    @focus="setInputFocusLog('inputFinished', true)"
                    @blur="setInputFocusLog('inputFinished', false)"
                />
            </div>

            <span v-else>{{ $t("Orders") }}</span>

            <div class="ibox-actions">
                <div
                    class="btn-search"
                    v-if="!showFilter || filterByResource"
                    @click="openFilter"
                >
                    <SearchIconBigger v-if="biggerIcon" />
                    <SearchIcon v-else />
                </div>

                <div
                    v-if="showOperations || showFinished"
                    class="btn-select"
                    @click="selectMode = true"
                >
                    {{ $t("Select") }}
                </div>
            </div>
        </div>

        <b-collapse
            class="tabs-filter"
            v-model="showFilter"
        >
            <div
                class="graph-buttons-resources"
                v-if="!filterByResource"
            >
                <button
                    type="button"
                    :class="['btn-graph-resources', showOperations ? 'btn-active' : 'btn-disabled']"
                    @click="
                        () => {
                            showOperations = true;
                            showResources = false;
                            showFinished = false;
                        }
                    "
                >
                    {{ $t("Orders") }}
                </button>

                <button
                    type="button"
                    :class="['btn-graph-resources', showResources ? 'btn-active' : 'btn-disabled']"
                    @click="
                        () => {
                            showResources = true;
                            showOperations = false;
                            showFinished = false;
                        }
                    "
                >
                    {{ $t("Operator.Resources") }}
                </button>

                <button
                    type="button"
                    :class="['btn-graph-resources', showFinished ? 'btn-active' : 'btn-disabled']"
                    @click="
                        () => {
                            showFinished = true;
                            showOperations = false;
                            showResources = false;
                        }
                    "
                >
                    {{ $t("Operator.Finished") }}
                </button>
            </div>

            <div
                v-else
                class="div-clean-filter"
            >
                <b-button
                    class="btn-clean"
                    @click="cleanFilter"
                >
                    {{ $t("Operator.CleanFilter") }}
                </b-button>

                <div class="chip-resource">
                    {{ filterByResource.name }}
                    <CloseFilter @click="cleanFilter" />
                </div>
            </div>
        </b-collapse>

        <div :class="{ 'div-content-menu-open': showFilter, 'div-content': !showFilter }">
            <Orders
                v-if="showOperations"
                @selectOperation="(id) => selectOperation(id)"
                @cleanSelectedOperations="cleanSelectedOperations"
                @loadMoreOperations="handleLoadMoreOperations"
                :selectMode.sync="selectMode"
                :operations="filteredOperations"
                :selectedOperations="operations.filter((f) => f.selected)"
                :is-first-loading="isOperationsFirstLoading"
            />

            <Resources
                v-if="showResources"
                @onClickResource="filterOperationsByResource"
                @loadMoreResources="handleLoadMoreResources"
                :resources="filteredResources"
            />

            <FinishedOrders
                :finishedOperations.sync="finishedOperations"
                @selectItem="selectFinishedOrder"
                :selectMode.sync="selectMode"
                v-if="showFinished"
                @loadMoreFinishedOrders="handleLoadMoreFinishedOperations"
                :dateList.sync="filteredFinisheds"
                ref="finishedOperations"
            />
        </div>
    </div>
</template>

<script>
    import { BCol, BRow, BCollapse, BButton } from "bootstrap-vue";
    import { mapMutations, mapGetters, mapActions } from "vuex";

    import Resources from "@/views/pages/operator/components/Resources/index.vue";
    import Orders from "@/views/pages/operator/components/OrderList.vue";
    import FinishedOrders from "@/views/pages/operator/components/FinishedOrders.vue";
    import Loading from "@/@core/components/loading";

    export default {
        components: {
            BCol,
            BRow,
            BCollapse,
            BButton,
            SearchIcon: () => import("@/assets/images/icons/search-icon.svg"),
            SearchIconBigger: () => import("@/assets/images/icons/search-icon-bigger.svg"),
            BackIcon: () => import("@/assets/images/icons/back.svg"),
            CloseFilter: () => import("@/assets/images/icons/close-filter-icon.svg"),
            BackIconBigger: () => import("@/assets/images/icons/back-icon-bigger.svg"),
            Resources,
            Orders,
            FinishedOrders
        },
        data() {
            return {
                currentSite: this.$cookies.get("userInfo").currentSite,
                prefix: localStorage.getItem("prefix") || this.$router.currentRoute.params.prefix,
                operations: [],
                resources: [],
                finishedOperations: [],
                showFilter: false,
                showOperations: true,
                showResources: false,
                showFinished: false,
                selectMode: false,
                filterByResource: null,
                filterByResourceBackup: null,
                biggerIcon: false,
                marginTop: "-90px",
                textFilter: "",
                textResource: "",
                textFinished: "",
                width: null,
                inputFocusLog: {
                    inputOrders: false,
                    inputResources: false,
                    inputFinished: false
                },
                isOperationsFirstLoading: true,
                isFirstLoading: true
            };
        },
        computed: {
            ...mapGetters("app", ["flagShowModalPin"]),
            filteredOperations() {
                const filterResourceId = this.filterByResourceBackup?.id;
                const selectedOperations = this.operations.filter((f) => f.selected);
                const disabledOperations = this.operations.filter((f) => f.disabled);
                const operations = this.operations;

                if (selectedOperations.length > 0) {
                    for (const operation of selectedOperations) {
                        const absentOperations = operations.filter(
                            (operation) => !disabledOperations.includes(operation)
                        );

                        if (operation.workcenter.controlByHour) {
                            for (let absentOrder of absentOperations) {
                                if (operation.operation.id != absentOrder.operation.id)
                                    this.$set(absentOrder, "disabled", true);
                            }
                        } else {
                            for (let absentOrder of absentOperations) {
                                if (
                                    operation.operation.id != absentOrder.operation.id &&
                                    (operation.operation.statusId !=
                                        absentOrder.operation.statusId ||
                                        absentOrder.workcenter.controlByHour)
                                )
                                    this.$set(absentOrder, "disabled", true);
                            }
                        }
                    }
                }

                const filteredOperations = !!filterResourceId
                    ? this.operations.filter((operation) => {
                          const resourceIds = operation.resource.map((resource) => resource.id);

                          return resourceIds.includes(filterResourceId) && operation.found;
                      })
                    : this.operations.filter((operation) => operation.found);

                filteredOperations.sort(function (a, b) {
                    if (a.selected === b.selected) {
                        return 0;
                    } else if (a.selected) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                return filteredOperations;
            },
            filteredResources() {
                this.resources.sort(function (a, b) {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                return this.resources.filter((resource) => resource.found);
            },
            filteredFinisheds() {
                const filteredOperations = this.finishedOperations
                    .map((date) => ({
                        ...date,
                        operations: date.operatorOrders.filter((operation) => operation.found)
                    }))
                    .filter((date) => date.operatorOrders.length > 0);

                return filteredOperations;
            }
        },
        async created() {
            window.addEventListener("resize", this.getSize);
            this.getSize();

            await Promise.all([
                this.getOperations(null, null),
                this.getResources(null),
                this.getFinishedOperations(null)
            ]).finally(() => {
                this.isFirstLoading = false;
            });
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapActions("app", ["removeRequestLoading"]),
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            },
            handleLoadMoreOperations() {
                if (this.isFirstLoading) return;

                const scroll = document.body.scrollHeight - 71.5; //desconsiderando footer da página

                if (scroll > window.innerHeight) {
                    let ids = [];

                    this.operations.forEach((operation) => {
                        if (!ids.includes(operation.operation.id)) ids.push(operation.operation.id);
                    });

                    ids.sort(function (a, b) {
                        return a - b;
                    });

                    const lastId = ids[ids.length - 1];

                    this.getOperations(lastId, this.filterByResource?.id);
                }
            },
            handleLoadMoreResources() {
                const scroll = document.body.scrollHeight - 71.5; //desconsiderando footer da página

                if (scroll > window.innerHeight) {
                    let ids = [];

                    this.resources.forEach((operation) => {
                        if (!ids.includes(operation.id)) ids.push(operation.id);
                    });

                    ids.sort(function (a, b) {
                        return a - b;
                    });

                    const lastId = ids[ids.length - 1];

                    this.getResources(lastId);
                }
            },
            handleLoadMoreFinishedOperations() {
                const scroll = document.body.scrollHeight - 71.5; //desconsiderando footer da página

                if (scroll > window.innerHeight) {
                    let ids = [];

                    this.finishedOperations.forEach((date) => {
                        date.operatorOrders.forEach((operation) => {
                            if (!ids.includes(operation.id)) ids.push(operation.id);
                        });
                    });

                    let idOut = Number.parseInt(ids[0]);
                    for (var j = 0; j < ids.length; j++) {
                        if (ids[j] != idOut + j) {
                            idOut = ids[j - 1];
                            break;
                        }
                    }

                    if (idOut == ids[0]) {
                        const lastOrderReturned = ids[ids.length - 1];

                        if (lastOrderReturned) this.getFinishedOperations(lastOrderReturned);
                    } else this.getFinishedOperations(idOut);
                }
            },
            async getOperations(lastOrderID = null, resourceId = null) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(
                        `/api/mysfc/operator/order?siteId=${this.currentSite}&completedOrders=false
                        ${lastOrderID ? `&id=${lastOrderID}` : ""}
                        ${this.textFilter ? `&filter=${this.textFilter}` : ""}
                        ${resourceId ? `&resourceId=${resourceId}` : ""}`
                    );

                    const operations =
                        response.data.data?.filter(
                            (i) => !this.operations.some((j) => i.operation.id == j.operation.id)
                        ) || [];

                    let newOperations = [];

                    if (this.textFilter) {
                        newOperations = [...operations, ...this.operations];
                    } else {
                        newOperations = [...this.operations, ...operations];
                    }

                    newOperations.forEach((operation) => {
                        operation.found = true;
                    });

                    this.filterOperations(newOperations);
                    this.isOperationsFirstLoading = false;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.removeRequestLoading();
                }
            },
            async getResources(lastResourceID = null) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(`/api/mysfc/operator/resource?siteId=${
                        this.currentSite
                    }
                    ${lastResourceID ? `&id=${lastResourceID}` : ""}
                    ${this.textResource ? `&filter=${this.textResource}` : ""}`);

                    let resources = response.data.filter(
                        (resource) => !this.resources.some((i) => i.id === resource.id)
                    );

                    if (this.textResource) {
                        resources.forEach((resource) => {
                            this.$set(resource, "found", true);
                        });
                        this.resources = [...resources, ...this.resources];
                    } else {
                        this.resources = [...this.resources, ...resources];
                        this.resources.forEach((resource) => {
                            this.$set(resource, "found", true);
                        });
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    this.removeRequestLoading();
                }
            },
            async getFinishedOperations(lastOrderID = null) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(
                        `/api/mysfc/operator/operation/completed?siteId=${this.currentSite}${
                            lastOrderID ? `&order_id=${lastOrderID}` : ""
                        }${this.textFinished ? `&filter=${this.textFinished}` : ""}`,
                        { headers: { IgnoreUUID: true } }
                    );

                    const operations = response.data.data;

                    let finishedOperations = operations.filter(
                        (operation) =>
                            !this.finishedOperations.some((i) => i.data === operation.data)
                    );
                    let finishedOperationsExistDate = operations.filter((operation) =>
                        this.finishedOperations.some((i) => i.data === operation.data)
                    );

                    if (this.textFinished) {
                        finishedOperations.forEach((date) => {
                            date.operatorOrders.forEach((operation) => {
                                this.$set(operation, "found", true);
                            });
                        });
                        finishedOperationsExistDate.forEach((date) => {
                            date.operatorOrders.forEach((operation) => {
                                this.$set(operation, "found", true);
                            });
                        });
                        finishedOperationsExistDate.forEach((existDate) => {
                            let date = this.finishedOperations.find(
                                (f) => f.data == existDate.data
                            );
                            if (date) {
                                let operationsNotExist = existDate.operatorOrders.filter(
                                    (f) =>
                                        !date.operatorOrders.some(
                                            (i) => i.operation.id === f.operation.id
                                        )
                                );
                                date.operatorOrders.push(...operationsNotExist);
                            }
                        });
                        this.finishedOperations = [
                            ...this.finishedOperations,
                            ...finishedOperations
                        ];
                    } else {
                        finishedOperationsExistDate.forEach((existDate) => {
                            let date = this.finishedOperations.find(
                                (f) => f.data == existDate.data
                            );
                            if (date) {
                                let operationsNotExist = existDate.operatorOrders.filter(
                                    (f) =>
                                        !date.operatorOrders.some(
                                            (i) => i.operation.id === f.operation.id
                                        )
                                );
                                date.operatorOrders.push(...operationsNotExist);
                            }
                        });
                        this.finishedOperations = [
                            ...this.finishedOperations,
                            ...finishedOperations
                        ];

                        this.finishedOperations.forEach((date) => {
                            date.operatorOrders.forEach((operation) => {
                                this.$set(operation, "found", true);
                            });
                        });
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    this.removeRequestLoading();
                }
            },
            cleanFilter() {
                this.filterByResource = null;
                this.filterByResourceBackup = null;
                this.showFilter = false;

                if (this.textFilter) this.openFilter();
            },
            getSize() {
                this.width =
                    window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth;

                if (this.width <= 768) {
                    this.biggerIcon = this.width > 535 ? true : false;
                    this.marginTop =
                        this.width > 535 ? "-80px" : this.showFilter ? "-78px" : "-100px";
                } else {
                    this.biggerIcon = false;
                    this.marginTop = "-92px";
                }
            },
            filterOperationsByResource(resource) {
                const resourceLocal = { id: resource.id, name: resource.name };

                this.filterByResource = { ...resourceLocal };
                this.filterByResourceBackup = { ...resourceLocal };

                this.showFilter = true;
                this.showOperations = true;
                this.showResources = false;
                this.showFinished = false;
                this.textFilter = "";

                this.getOperations(null, resource.id);
            },
            openFilter() {
                this.showFilter = true;
                this.filterByResource = null;

                this.$nextTick(() => {
                    this.$refs.inputOrders.focus();
                });
            },
            closeFilter() {
                if (this.filterByResourceBackup)
                    this.filterByResource = this.filterByResourceBackup;
                else this.showFilter = false;

                this.textFilter = "";
                this.textResource = "";
                this.textFinished = "";
                this.showOperations = true;
                this.showResources = false;
                this.showFinished = false;
            },
            filterOperations(newOperations) {
                const { textFilter, operations } = this;

                const localOperations = newOperations ?? operations;

                if (textFilter) {
                    this.operations = localOperations.map((operation) => {
                        return {
                            ...operation,
                            found:
                                operation?.order?.number
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.includes(textFilter.toLowerCase()) ||
                                operation?.resource?.some((i) =>
                                    i?.name
                                        ?.toString()
                                        ?.toLowerCase()
                                        ?.includes(textFilter.toLowerCase())
                                )
                        };
                    });

                    return;
                }

                this.operations = localOperations.map((operation) => ({
                    ...operation,
                    found: true
                }));
            },
            filterResources() {
                const { textResource, resources } = this;

                if (textResource) {
                    resources.forEach((resource) => {
                        resource.found = resource.name
                            .toLowerCase()
                            .includes(textResource.toLowerCase());
                    });

                    return;
                }

                resources.forEach((resource) => (resource.found = true));
            },
            filterFinished() {
                const { textFinished, finishedOperations } = this;

                if (textFinished) {
                    finishedOperations.forEach((date) => {
                        date.operatorOrders.forEach((operation) => {
                            operation.found =
                                operation.order_no
                                    .toLowerCase()
                                    .includes(textFinished.toLowerCase()) ||
                                operation.resource
                                    .toLowerCase()
                                    .includes(textFinished.toLowerCase());
                        });
                    });

                    return;
                }

                finishedOperations.forEach((date) => {
                    date.operatorOrders.forEach((operation) => (operation.found = true));
                });
            },
            selectFinishedOrder({ operationId, selectedOperationsCount }) {
                if (!operationId) return;

                if (selectedOperationsCount === 0) {
                    this.$refs.finishedOperations.resetOrders();
                    return;
                }

                let operation;
                for (let i = 0; i < this.finishedOperations.length; i++) {
                    const finishedItemIndex = this.finishedOperations[i].operatorOrders.findIndex(
                        (item) => item.operation.id == operationId
                    );
                    if (finishedItemIndex !== -1) {
                        operation = this.finishedOperations[i].operatorOrders[finishedItemIndex];
                        break;
                    }
                }

                if (!operation?.disabled) {
                    if (operation) {
                        this.$set(operation, "selected", !operation.selected);
                    }
                }

                // Verificar somente quando tiver 1 item selecionado para evitar
                // execuções desnecessárias
                if (selectedOperationsCount !== 1) return;

                this.finishedOperations.forEach((date) => {
                    date.operatorOrders.forEach((item) => {
                        if (item.operation.id == operationId) return;

                        if (operation.workcenter.controlByHour) {
                            this.$set(item, "disabled", operation.selected);
                            return;
                        }

                        if (
                            !operation.workcenter.controlByHour &&
                            (item.workcenter.controlByHour ||
                                item.operation.isRework != operation.operation.isRework)
                        )
                            this.$set(item, "disabled", operation.selected);
                    });
                });
            },
            selectOperation(id) {
                const operation = this.operations.find((f) => f.operation.id === id);

                if (operation.disabled || !operation) return;

                this.$set(operation, "selected", !operation.selected);

                this.operations.forEach((item) => {
                    if (item.operation.id == id) return;

                    if (operation.workcenter.controlByHour) {
                        this.$set(item, "disabled", operation.selected);
                        return;
                    }

                    if (
                        !operation.workcenter.controlByHour &&
                        (item.operation.statusId != operation.operation.statusId ||
                            item.workcenter.controlByHour ||
                            operation.operation.isRework != item.operation.isRework)
                    )
                        this.$set(item, "disabled", operation.selected);
                });
            },
            cleanSelectedOperations() {
                this.operations.forEach((operation) => {
                    this.$set(operation, "selected", false);
                    this.$set(operation, "disabled", false);
                });
            },
            addAndRemoveInputsFocus(action) {
                const { showOperations, showResources, showFinished, inputFocusLog } = this;
                const { inputOrders, inputResources, inputFinished } = this.$refs;

                const execAction = (input, inputNameRef) => {
                    inputFocusLog[inputNameRef] && action === "remove" && input?.blur();
                    inputFocusLog[inputNameRef] && action === "add" && input.focus();
                };

                showOperations && execAction(inputOrders, "inputOrders");
                showResources && execAction(inputResources, "inputResources");
                showFinished && execAction(inputFinished, "inputFinished");
            },
            setInputFocusLog(inputName, value) {
                if (!this.flagShowModalPin) this.inputFocusLog[inputName] = value;
            }
        },
        watch: {
            showOperations(value) {
                if (!!value) {
                    this.scrollToTop();

                    this.$nextTick(() => {
                        let input = this.$refs.inputOrders;

                        if (input) input.focus();
                    });
                }
            },
            showFinished(value) {
                if (!!value) {
                    this.scrollToTop();

                    this.$nextTick(() => {
                        this.$refs.inputFinished.focus();
                    });
                }
            },
            showResources(value) {
                if (!!value) {
                    this.scrollToTop();

                    this.$nextTick(() => {
                        this.$refs.inputResources.focus();
                    });
                }
            },
            textFilter() {
                this.filterOperations();
            },
            textResource() {
                this.filterResources();
            },
            textFinished() {
                this.filterFinished();
            },
            flagShowModalPin(value) {
                this.addAndRemoveInputsFocus(value ? "remove" : "add");
            }
        },
        updated() {
            this.getSize();
        }
    };
</script>

<style lang="scss">
    .app-content-container:has(.app-content-operator) {
        min-height: 92vh;
    }
</style>

<style lang="scss" scoped>
    .container-operator-orders {
        .div-content {
            margin-top: 4rem;
        }

        .div-content-menu-open {
            margin-top: 8rem;
        }

        .ibox-actions {
            display: flex;
            align-items: center;
            gap: 15px;
        }

        .operator-orders-header {
            padding: 16px 29px;
            position: fixed;
            top: 49px;
            right: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;

            background: #ffffff;
            color: #4c4541;
            z-index: 2;
            border-top: 1px solid #cfc4be;

            &:has(.input-search-finished) {
                gap: 16px !important;
                .ibox-filter,
                .input-search-finished {
                    flex: 1 !important;
                }
            }
        }

        .tabs-filter {
            width: 100vw;
            padding: 0px;
            position: fixed;
            margin-left: -28px;
            margin-top: -92px;
            background: #ffffff;
            box-shadow: 0px 8px 30px rgba(58, 46, 38, 0.1);
            z-index: 1;

            .graph-buttons-resources {
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 58px;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none !important;
                }

                .btn-graph-resources {
                    min-width: 120px;
                    font-size: 16px;
                    border: none;
                }

                .btn-active {
                    width: 100%;
                    background-color: transparent;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    color: #974900;
                    box-shadow: inset 0px -4px 0px #974900;
                }

                .btn-disabled {
                    width: 100%;
                    background-color: transparent;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    color: #998f8a;
                    border-bottom: 1px solid #cfc4be;
                }
            }

            .div-clean-filter {
                display: flex;
                align-items: center;
                height: 62px;
                padding-left: 30px;
                gap: 18px;

                .btn-clean {
                    width: 102px;
                    height: 28px;
                    background: #974900 !important;
                    padding: 2px 14px;
                    border-radius: 5px;

                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    color: #ffffff !important;
                    border: none !important;

                    &:hover {
                        box-shadow: none;
                        background: #9f5714 !important;
                    }
                }

                .chip-resource {
                    padding: 6px 8px 6px 12px;
                    height: 32px;
                    background: #ffdbc4;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    color: #974900;
                    gap: 8px;

                    svg {
                        cursor: pointer;
                    }
                }
            }
        }

        .btn-search {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            cursor: pointer;
            width: 38px;
            height: 38px;

            &:hover {
                background-color: #ffede2;
            }
        }

        .btn-select {
            cursor: pointer;
            width: 110px;
            height: 38px;
            font-size: 14px;
            line-height: 20px;
            border-radius: 5px;
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.0125em;
            font-feature-settings:
                "pnum" on,
                "lnum" on;

            color: #974900;

            &:hover {
                background-color: #ffede2;
            }
        }

        .ibox-filter {
            display: flex;
            align-items: center;
            gap: 20px;

            input {
                border: none;
                color: #cfc4be;
                font-size: 14px;
                line-height: 20px;
                height: 20px;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #cfc4be;
                }
            }

            .input-search-finished {
                max-width: unset !important;
                width: unset !important;
            }
        }

        .header-divider {
            width: 1px;
            height: 30px;
            background: #cfc4be;
        }
    }

    @media screen and (max-width: 768px) {
        .container-operator-orders {
            .operator-orders-header {
                font-size: 36px;
                line-height: 50px;
                height: 82px;
            }

            .btn-search {
                width: 63px;
                height: 63px;
            }

            .btn-select {
                width: 166px;
                height: 63px;
                font-size: 20px;
                line-height: 23px;
            }

            .ibox-filter {
                input {
                    font-size: 22px;
                    line-height: 30px;

                    &::placeholder {
                        font-size: 22px;
                        line-height: 30px;
                    }
                }
            }

            .header-divider {
                height: 50px;
            }

            .tabs-filter {
                .div-clean-filter {
                    overflow-x: scroll;
                    white-space: nowrap;
                    scrollbar-width: 0px;
                    -ms-overflow-style: none;
                    padding: 0px 16px 0px 32px;

                    &::-webkit-scrollbar {
                        display: none !important;
                    }

                    .btn-clean {
                        font-size: 14px;
                        line-height: 20px;
                        width: 129px;
                    }

                    .chip-resource {
                        font-size: 17px;
                        line-height: 20px;
                        cursor: grab;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 575px) {
        .container-operator-orders {
            .operator-orders-header {
                padding: 16px;
            }

            .tabs-filter {
                margin-left: -16px;
            }
        }
    }

    @media screen and (max-width: 535px) {
        .container-operator-orders {
            .div-content {
                margin-top: 3rem;
            }

            .div-content-menu-open {
                margin-top: 6.6rem;
            }

            .operator-orders-header {
                font-size: 18px;
                line-height: 26px;
                height: 62px;
            }

            .btn-search {
                width: 28px;
                height: 28px;
            }

            .btn-select {
                width: 86px;
                height: 28px;
                font-size: 12px;
                line-height: 20px;
            }

            .ibox-filter {
                input {
                    font-size: 12px;
                    line-height: 16px;

                    &::placeholder {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .header-divider {
                height: 26px;
            }

            .tabs-filter {
                .graph-buttons-resources {
                    height: 52px;

                    .btn-active,
                    .btn-disabled {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                .div-clean-filter {
                    padding: 16px;
                    gap: 15px;

                    .btn-clean {
                        font-size: 12px;
                        line-height: 20px;
                        width: 102px;
                        height: 28px;
                    }

                    .chip-resource {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
</style>
