<template>
    <div class="location-field">
        <ModalHeader :title="modalName" :close="closeModal" />
        <div class="field-body">
            <div id="dropdown-block" class="dropdown-block">
                <div class="input-block">
                    <b-form-group class="group-input">
                        <SearchIcon class="search-icon" />
                        <b-form-input
                            id="location-input"
                            :value="locationFilter"
                            :placeholder="$t('Operator.PlaceholderLocation')"
                            type="text"
                            class="input"
                            @input.native="(e) => searchByAddress(e.target.value)"
                            @focus="device !== 'desktop' && fullScreenDropdown()"
                        />
                    </b-form-group>
                    <Button
                        v-if="device !== 'desktop'"
                        class="button-cancel"
                        button-type="side"
                        @click="removeFullScreenDropdown"
                    >
                        {{ $t("IntegrationPage.cancel") }}
                    </Button>
                </div>
                <div class="horizontal-line" />

                <div class="group-locations">
                    <div v-for="location in suggestions" :key="location.place_id">
                        <div
                            :class="[
                                'location-block',
                                selectedLocation === location.place_id ? 'selected' : ''
                            ]"
                            @mouseenter="hoveredLocation = location.place_id"
                            @mouseleave="hoveredLocation = null"
                            @click="selectAddress(location.place_id)"
                        >
                            <LocationIcon class="location-icon" />
                            <div>{{ location.name }}</div>
                        </div>
                        <div
                            :class="[
                                'horizontal-line',
                                hoveredLocation === location.place_id ? 'hovered-line' : ''
                            ]"
                        />
                    </div>
                </div>

                <div class="horizontal-line" />
                <div class="footer-locations">
                    <Button button-type="side" @click="getCurrentLocation">
                        <CurrentLocation />
                        {{ $t("Operator.UseCurrentLocation") }}
                    </Button>
                </div>
            </div>
            <Map
                id="map-block"
                class="map-block"
                :current-coordinates="currentCoordinates"
                @updateLocation="updateLocation"
                @click="fullScreenMap"
            />
            <div id="map-options" class="map-options">
                <div class="address">
                    <LocationIcon class="location-icon" />
                    {{ suggestions.length ? suggestions[0].name : "" }}
                </div>
                <div class="horizontal-line" />
                <div class="buttons">
                    <Button button-type="side" @click="removeFullScreenMap">
                        {{ $t("IntegrationPage.cancel") }}
                    </Button>
                    <Button button-type="save" @click="confirmLocation">
                        {{ $t("Operator.ConfirmLocation") }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ModalHeader from "@/views/pages/operator/components/InputTextModal/components/ModalHeader/ModalHeader.vue";
    import { BFormInput, BFormGroup } from "bootstrap-vue";
    import SearchIcon from "@core/assets/formatted-icons/search-icon.svg";
    import CurrentLocation from "@core/assets/icons/current-location.svg";
    import LocationIcon from "@core/assets/icons/location-icon.svg";
    import Button from "@/views/pages/operator/components/InputTextModal/components/Button/Button.vue";
    import { useGoogleMapsLoader } from "@/libs/loader.js";
    import Map from "./components/Map.vue";

    export default {
        components: {
            Map,
            ModalHeader,
            BFormInput,
            BFormGroup,
            SearchIcon,
            LocationIcon,
            Button,
            CurrentLocation
        },
        props: {
            modalName: {
                type: String,
                default: ""
            },
            value: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                locationFilter: "",
                hoveredLocation: null,
                selectedLocation: null,
                autoCompleteService: null,
                placesService: null,
                sessionToken: null,
                currentCoordinates: {
                    lat: 0,
                    lng: 0
                },
                suggestions: [],
                device:
                    window.innerWidth > 768
                        ? "desktop"
                        : window.innerWidth > 425 && window.innerWidth <= 768
                        ? "tablet"
                        : "mobile"
            };
        },
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        destroyed() {
            window.removeEventListener("resize", this.handleResize);
        },
        mounted() {
            this.addOpenAnimation();
            if (this.value) {
                const { lat, lng } = this.value.coordinates;
                this.currentCoordinates = { lat, lng };
                this.getPlaces();
            } else {
                this.$getLocation({})
                    .then((coordinates) => {
                        this.currentCoordinates = coordinates;
                        this.getPlaces();
                    })
                    .catch((error) => alert(error));
            }
            localStorage.setItem("coordinates", JSON.stringify(this.currentCoordinates));
        },
        methods: {
            handleResize() {
                this.device =
                    window.innerWidth > 768
                        ? "desktop"
                        : window.innerWidth > 425 && window.innerWidth <= 768
                        ? "tablet"
                        : "mobile";
            },
            addOpenAnimation() {
                const modal = document.querySelector(".location-field");
                modal.classList.add("animation-open-modal-text");

                setTimeout(
                    () =>
                        document
                            .querySelector(".location-field")
                            .classList.remove("animation-open-modal-text"),
                    1000
                );
            },
            async getPlaces() {
                const { loadGoogleMaps } = useGoogleMapsLoader();
                await loadGoogleMaps();

                this.autoCompleteService = new google.maps.places.AutocompleteService();
                const map = new google.maps.Map(document.createElement("div"));
                this.placesService = new google.maps.places.PlacesService(map);
                this.placesService.nearbySearch(
                    {
                        location: this.currentCoordinates,
                        radius: 100
                    },
                    (places, status) => {
                        const placesFiltered = [];
                        places.map((e) => {
                            if (
                                !e.types.includes("locality") &&
                                !e.types.includes("sublocality") &&
                                !e.types.includes("country")
                            ) {
                                placesFiltered.push({
                                    place_id: e.place_id,
                                    name: e.vicinity
                                });
                            }
                        });
                        this.suggestions = placesFiltered;
                    }
                );
            },
            fullScreenDropdown() {
                const dropdownBlock = document.getElementById("dropdown-block");
                const mapBlock = document.getElementById("map-block");

                dropdownBlock.classList.add("full-screen");
                mapBlock.classList.add("minimized");
            },
            removeFullScreenDropdown() {
                const dropdownBlock = document.getElementById("dropdown-block");
                const mapBlock = document.getElementById("map-block");

                dropdownBlock.classList.remove("full-screen");
                mapBlock.classList.remove("minimized");
            },
            fullScreenMap() {
                const dropdownBlock = document.getElementById("dropdown-block");
                const mapBlock = document.getElementById("map-block");
                const mapOptions = document.getElementById("map-options");

                mapBlock.classList.add("full-screen");
                mapOptions.classList.add("showed");
                dropdownBlock.classList.add("minimized");
            },
            removeFullScreenMap() {
                const dropdownBlock = document.getElementById("dropdown-block");
                const mapBlock = document.getElementById("map-block");
                const mapOptions = document.getElementById("map-options");

                mapBlock.classList.remove("full-screen");
                mapOptions.classList.remove("showed");
                dropdownBlock.classList.remove("minimized");
            },
            getCurrentLocation() {
                this.$getLocation({})
                    .then((coordinates) => {
                        this.currentCoordinates = coordinates;
                        this.getPlaces();
                        localStorage.setItem("coordinates", JSON.stringify(coordinates));
                    })
                    .catch((error) => alert(error));

                this.removeFullScreenDropdown();
                this.fullScreenMap();
                this.getPlaces();
            },
            updateLocation(latLng) {
                this.currentCoordinates = latLng;
                this.getPlaces();
            },
            confirmLocation(place_id, address_name) {
                const firstSuggestion = this.suggestions[0];
                const payload = {
                    coordinates: this.currentCoordinates,
                    place_id: place_id || firstSuggestion.place_id,
                    address_name: address_name || firstSuggestion.name
                };
                this.$emit("setValue", payload);
                this.closeModal();
            },
            selectAddress(place_id) {
                this.selectedLocation = place_id;
                this.placesService.getDetails(
                    {
                        placeId: place_id,
                        fields: ["name", "formatted_address", "geometry.location"],
                        sessionToken: this.sessionToken
                    },
                    (place, status) => {
                        this.currentCoordinates = {
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng()
                        };
                        this.confirmLocation(place_id, place.formatted_address);
                    }
                );
                this.sessionToken = null;
            },
            searchByAddress(inputValue) {
                if (!this.sessionToken) {
                    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
                }
                this.autoCompleteService.getPlacePredictions(
                    { input: inputValue, sessionToken: this.sessionToken },
                    (prediction, status) => {
                        const placesFiltered = [];
                        if (prediction) {
                            prediction.map((e) => {
                                placesFiltered.push({
                                    place_id: e.place_id,
                                    name: e.description
                                });
                            });
                        }
                        this.suggestions = placesFiltered.length > 0 ? placesFiltered : [];
                    }
                );
            },
            closeModal() {
                const modal = document.querySelector(".location-field");
                modal.classList.add("animation-close-modal");
                modal.classList.add("closed-modal");
                setTimeout(() => {
                    this.$emit("close");
                }, 1000);
            }
        }
    };
</script>

<style lang="scss">
    .location-field {
        width: 100vw;
        height: 100dvh;
        background: #fff;
        z-index: 99999999;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;

        .field-body {
            display: flex;
            justify-content: space-between;
            height: 100%;
            border: 1px solid #cfc4be;
            background: #fff;

            .dropdown-block {
                width: 60%;

                .group-input {
                    border-radius: 5px;
                    border: 1px solid #cfc4be;
                    margin: 24px 29px;

                    div {
                        display: flex;
                        align-items: center;
                        gap: 14px;
                        padding: 0 14px;

                        .search-icon {
                            width: 14px;
                            height: 14px;
                            fill: #cfc4be;
                        }

                        .input {
                            border: none;
                            box-shadow: none;
                            outline: none;
                            padding-inline: 0;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            color: #4c4541;
                        }
                    }
                }

                .group-locations {
                    overflow-y: auto;
                    height: calc(100dvh - 247px);
                    overflow-x: hidden;
                    .location-block {
                        display: flex;
                        gap: 16px;
                        padding: 32px 29px;
                        cursor: pointer;

                        .location-icon {
                            width: 24px;
                            height: 24px;
                            fill: #4c4541;
                        }

                        &:hover {
                            background-color: #ffede2;
                            color: #974900;

                            .location-icon {
                                fill: #974900;
                            }
                        }

                        &:active {
                            background-color: #ffdbc4;
                            color: #974900;

                            .location-icon {
                                fill: #974900;
                            }
                        }

                        &.selected {
                            background-color: #974900;
                            color: #fff;

                            .location-icon {
                                fill: #fff;
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 7px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #fff;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #cfc4be;
                        border-radius: 9px;
                    }
                }

                .footer-locations {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 24px 29px;

                    button {
                        width: 100%;
                        display: flex;
                        gap: 12px;
                        color: #974900;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.213px;
                        height: 46px !important;
                    }
                }
            }

            .map-block {
                width: 40%;
            }

            .map-options {
                display: none;
            }
        }

        .horizontal-line {
            width: 100vw;
            height: 1px;
            background: #cfc4be;
            transition: background-color 0.3s ease;

            &.hovered-line {
                background: #974900;
            }
        }

        &.closed-modal {
            top: 100dvh;
        }

        &.animation-open-modal-text {
            animation: openModal 0.5s normal;
            @keyframes openModal {
                0% {
                    top: 100dvh;
                }
                100% {
                    top: 0;
                }
            }
        }

        &.animation-close-modal {
            animation: closeModal 0.5s normal;
            @keyframes closeModal {
                0% {
                    top: 0;
                }
                100% {
                    top: 100dvh;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .location-field {
            .field-body {
                display: flex;
                flex-direction: column;

                .dropdown-block {
                    order: 2;
                    width: 100%;
                    height: 50%;
                    transition: all 0.5s;

                    .group-locations {
                        overflow-x: hidden;
                        height: calc(50dvh - 225px);
                    }

                    .button-cancel {
                        width: max-content;
                        display: none;
                    }

                    &.full-screen {
                        height: 100%;

                        .input-block {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 16px;

                            .group-input {
                                width: 100%;
                                margin-right: 0;

                                div input {
                                    height: 50px;
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 26px;
                                }
                            }

                            button {
                                max-width: 130px;
                                width: 100%;
                                padding: 13px 28px;
                                margin-right: 29px;
                                border: none;
                                color: #974900;
                                font-size: 17px;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.213px;
                            }
                        }

                        .group-locations {
                            height: calc(100dvh - 268px);
                        }

                        .button-cancel {
                            display: flex;
                        }
                    }
                    &.minimized {
                        display: none;
                    }
                }

                .map-block {
                    width: 100%;
                    height: 50%;
                    transition: all 0.5s;

                    &.full-screen {
                        height: 100%;
                    }
                    &.minimized {
                        display: none;
                    }
                }

                .map-options.showed {
                    display: flex;
                    flex-direction: column;

                    .address {
                        display: flex;
                        align-items: center;
                        padding: 32px 29px;
                        gap: 16px;
                        color: #4c4541;
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 30px;

                        .location-icon {
                            width: 24px;
                            height: 24px;
                            fill: #4c4541;
                        }
                    }

                    .buttons {
                        display: flex;
                        gap: 16px;
                        width: 100%;
                        padding: 24px 29px;

                        .brown-button,
                        .white-button {
                            margin: 0;
                            width: 100%;
                            text-align: center;
                            font-size: 20px !important;
                            font-weight: 600;
                            letter-spacing: 0.25px;
                            padding: 20px 0;
                            height: 63px !important;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        .location-field {
            .field-body {
                .dropdown-block {
                    .group-input {
                        margin: 16px;
                    }

                    .group-locations {
                        height: calc(50dvh - 160px);
                        .location-block {
                            padding: 16px;
                        }
                    }

                    .footer-locations {
                        padding: 16px;

                        button {
                            width: 100%;
                            display: flex;
                            gap: 12px;
                            font-size: 12px;
                            line-height: 20px;
                            letter-spacing: 0.15px;
                            height: unset !important;
                        }
                    }

                    &.full-screen {
                        .input-block {
                            .group-input {
                                div input {
                                    height: 38px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }

                            button {
                                max-width: 80px;
                                padding: 4px 14px;
                                margin-right: 16px;
                                font-size: 12px;
                                line-height: 20px;
                                letter-spacing: 0.15px;
                            }
                        }

                        .group-locations {
                            height: calc(100dvh - 193px);
                        }
                    }
                }

                .map-options.showed {
                    .address {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        padding: 16px;
                    }

                    .buttons {
                        display: flex;
                        flex-direction: column-reverse;
                        gap: 8px;
                        width: 100%;
                        padding: 24px 29px;

                        .brown-button,
                        .white-button {
                            font-size: 12px !important;
                            line-height: 20px;
                            letter-spacing: 0.15px;
                            padding: 4px 0;
                            height: 28px !important;
                        }
                    }
                }
            }
        }
    }
</style>
