<template>
    <div class="quantity-consumed-input__container">
        <sys-button
            class="quantity-consumed-input__button"
            variant="outline"
            :size="windowWidth > 570 && windowWidth <= 768 ? 'lg' : 'md'"
            @click="$emit('decrement')"
            icon
        >
            <LessIcon />
        </sys-button>

        <div class="quantity-consumed-input__quantity-box">
            <b-form-group :invalid-feedback="$t('RequiredField')">
                <b-form-input
                    type="text"
                    placeholder="0,00"
                    class="quantity-consumed-input__quantity-input"
                    :value="value"
                    :state="state"
                    :formatter="formatter"
                    @input="(value) => $emit('input', value)"
                    @blur="$emit('blur')"
                    @change="$emit('change')"
                />
            </b-form-group>
        </div>

        <sys-button
            class="quantity-consumed-input__button"
            variant="outline"
            :size="windowWidth > 570 && windowWidth <= 768 ? 'lg' : 'md'"
            @click="$emit('increment')"
            icon
        >
            <PlusIcon />
        </sys-button>
    </div>
</template>

<script>
import SysButton from "@/@core/components/sys-button/index.vue";
import PlusIcon from "@/@core/assets/formatted-icons/plus-icon.svg";
import LessIcon from "@/@core/assets/formatted-icons/less-icon.svg";
import { BFormGroup, BFormInput } from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
    components: {
        SysButton,
        PlusIcon,
        LessIcon,
        BFormGroup,
        BFormInput,
    },
    computed: {
        ...mapGetters("app", ["windowWidth"]),
    },
    props: {
        value: {
            type: String,
            required: false,
        },
        state: {
            type: Boolean,
            required: false,
        },
        formatter: {
            type: Function,
            required: false,
        },
    },
};
</script>

<style lang="scss">
#orders-group-material-modal {
    .quantity-consumed-input__container {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;

        .quantity-consumed-input__quantity-box {
            width: 100%;
            .form-group {
                margin: 0;
                position: relative;
            }

            .quantity-consumed-input__quantity-input {
                text-align: center;
            }

            .invalid-feedback {
                position: absolute;
                top: 100%;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 571px) {
    #orders-group-material-modal {
        .quantity-consumed-input__container {
            .quantity-consumed-input__quantity-box {
                .quantity-consumed-input__quantity-input {
                    min-height: 50px !important;
                }
            }
        }
    }
}
</style>
