<template>
    <div class="order-list-modal-helper">
        <div>
            <OrderDetailsModal
                v-if="showDetailsModal"
                :orderData="orderData"
                :open="showDetailsModal"
                :isMultipleOrders="isMultipleOrders"
                @close="closeDetailsModal"
                @closeListModal="closeModal"
            >
                <template v-slot:back-button>
                    <div class="arrow-left-container">
                        <ArrowLeft />
                    </div>
                </template>
            </OrderDetailsModal>
        </div>

        <div class="order-list-modal-container animation-open-modal">
            <ModalHeader :close="closeModal" />

            <ul class="list-content">
                <ItemList
                    v-for="({ operationId, operationLabel }, index) in orderList"
                    :key="`order-${index}`"
                    @select-item="selectItem(operationId)"
                >
                    <span>{{ operationLabel }}</span>
                </ItemList>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations } from "vuex";
    import OrderDetailsModal from "@/views/pages/operator/components/OrderModal/OrderModal.vue";
    import ModalHeader from "./components/ModalHeader";
    import ItemList from "./components/ItemList";
    import ArrowLeft from "@/assets/images/new-icons/arrow-left-icon.svg";

    export default {
        components: {
            ModalHeader,
            ItemList,
            OrderDetailsModal,
            ArrowLeft
        },
        props: {
            orderList: {
                type: Array,
                default: [],
                required: true
            },
            isMultipleOrders: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                orderData: {},
                showDetailsModal: false,
                currentSite: this.$cookies.get("userInfo").currentSite
            };
        },
        mounted() {
            this.addOpenAnimation();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapActions("app", ["removeRequestLoading"]),
            async selectItem(id) {
                const order = await this.fetchOrder(id);

                this.orderData = { ...order };
                this.showDetailsModal = true;
            },
            closeDetailsModal() {
                this.showDetailsModal = false;
            },
            addOpenAnimation() {
                const modal = document.querySelector(".order-list-modal-container");
                modal.classList.add("animation-open-modal");

                setTimeout(
                    () =>
                        document
                            .querySelector(".order-list-modal-container")
                            .classList.remove("animation-open-modal"),
                    1000
                );
            },
            closeModal() {
                const modal = document.querySelector(".order-list-modal-container");
                modal.classList.add("animation-close-modal");
                modal.classList.add("closed-modal");
                setTimeout(() => this.$emit("close"), 500);
                this.UPDATE_FLAG_SHOW_OVERLAY(false);
            },
            async fetchOrder(id) {
                const ORDER = `/api/mysfc/operator/operation/${id}?siteId=${this.currentSite}`;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                const response = await this.$http2.get(ORDER);
                this.removeRequestLoading();

                return response?.data?.data;
            }
        }
    };
</script>

<style lang="scss">
    .order-list-modal-helper {
        .arrow-left-container {
            width: 28px;
            height: 28px;
            margin-right: 16px;
            border-radius: 5px;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: #ffede2;
            }

            &:active {
                background: #ffdbc4;
            }

            svg {
                width: 7.5px;
                height: 12px;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    @media (max-width: 768px) and (min-width: 425px) {
        .order-list-modal-helper {
            .arrow-left-container {
                height: 46px !important;
                width: 46px !important;

                svg {
                    width: 11.25px !important;
                    height: 18px !important;
                }
            }
        }
    }

    @media (max-width: 425px) {
        .order-list-modal-helper {
            .arrow-left-container {
                margin-right: 8px !important;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .order-list-modal-container {
        overflow: auto;
        height: 100dvh;
        width: 100vw;
        background: #fcfcfc;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9990;
        display: flex;
        flex-direction: column;

        &.closed-modal {
            top: 100vh;
        }

        &.animation-open-modal {
            animation: openModal 0.5s normal;
            @keyframes openModal {
                0% {
                    top: 100vh;
                }
                100% {
                    top: 0;
                }
            }
        }

        &.animation-close-modal {
            animation: closeModal 0.5s normal;
            @keyframes closeModal {
                0% {
                    top: 0;
                }
                100% {
                    top: 100vh;
                }
            }
        }

        .list-content {
            &::-webkit-scrollbar {
                width: 16px; /* width of the entire scrollbar */
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be; /* color of the scroll thumb */
                border-radius: 20px; /* roundness of the scroll thumb */
                border: 4px solid #fff;
                height: 70px;
            }

            overflow: auto;
            padding: 0;
            margin: 0;
        }
    }
</style>
